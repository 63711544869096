
import { Component, Prop, Vue } from 'vue-property-decorator';
import { get, ref } from 'firebase/database';
import { db } from '@/firebase';

interface JDR {
    title: string;
    description: string;
    lifespan: string;
    players: string;
    pricing: string;
    tags: string[];
}

@Component
export default class JdrComponent extends Vue {
    @Prop() private id!: string;

    jdr: JDR | null = null;

    async created(): Promise<void> {
        try {
            const jdrsRef = ref(db, `/content/${this.id}`);
            await get(jdrsRef).then((snapshot) => {
                this.jdr = snapshot.val();
            });
        } catch (error) {
            console.log('error : ' + error);
        }
    }
}
