
import {Component, Vue} from 'vue-property-decorator';
import {get, ref} from 'firebase/database';
import {db} from '@/firebase';
import GameListItem from '@/components/GameListItem.vue';
import {Game} from '@/types/Interfaces';

@Component({
    components: {
        GameListItem,
    },
})

export default class Games extends Vue {

    games: Game[] | null = null;
    admin = false;

    async created(): Promise<void> {
        try {
            const jdrsRef = ref(db, `/jdrs`);
            await get(jdrsRef).then((snapshot) => {
                this.games = snapshot.val();
            });
        } catch (error) {
            console.log('error : ' + error);
        }
        //removes all games without the current user
        if (this.games) {
            this.games = Object.values(this.games).filter(game => {
                return game.mjId == this.$store.state.user.uid || (game.characters && Object.keys(game.characters).indexOf(`${this.$store.state.user.uid}`) !== -1);
            });
        }

        try {
            const userRef = ref(db, `/users/${this.$store.state.user.uid}`);
            await get(userRef).then((snapshot) => {
                this.admin = snapshot.val().isAdmin;
            });
        } catch (error) {
            console.error('Permison denied');
        }
    }
}
