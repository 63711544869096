
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Game } from '@/types/Interfaces';
import { ref, set } from 'firebase/database';
import { db, storage } from '@/firebase';
import PadoCharSheetAbilityComponent from '@/components/ParceDomine/PadoCharSheetAbilityComponent.vue';
import { getDownloadURL, ref as storageRef, ref as refStorage, StorageReference, uploadBytes } from 'firebase/storage';
import AzrCharSheetAbilityComponent from "@/components/Azuria/AzrCharSheetAbilityComponent.vue";

@Component({
    components: { AzrCharSheetAbilityComponent, PadoCharSheetAbilityComponent },
})
export default class LotdCharSheetComponent extends Vue {
    @Prop() private game !: Game;
    @Prop() private id !: number;

    modal = false;
    modalText = '';
    currentModal = '';
    currentType = '';

    cssBlur = '';

    mounted(): void {
        this.loadImage();
    }

    classe = this.game.characters[this.id].informations[1].value
    sousClasse = this.game.characters[this.id].informations.filter(i => i.name == 'sous-classe')[0].value 

    @Watch('classe') async onClassChange(): Promise<void> {
        console.log(this.classe)
        // reset characteristics on class change
        const baseCharacteristics = [
            {
                name: 'force',
                value: 10,
                color: '#87f8a8'
            },
            {
                name: 'agilité',
                value: 10,
                color: '#87f8a8'
            },
            {
                name: 'rapidité',
                value: 10,
                color: '#87f8a8'
            },
            {
                name: 'robustesse',
                value: 10,
                color: '#87f8a8'
            },
            {
                name: 'perception',
                value: 10,
                color: '#ffe599'
            },
            {
                name: 'visée',
                value: 10,
                color: '#ffe599'
            },
        ];

        const baseInformations = [
                        {
                            name: 'nom',
                            value: ''
                        },
                        {
                            name: 'classe',
                            value: 'vampire'
                        },
                        {
                            name: 'biographie',
                            value: ''
                        },
                        {
                            name: 'niveau',
                            value: 1
                        },
                        {
                            name: 'sous-classe',
                            value: 'loup'
                        }
                    ]

        this.game.characters[this.id].characteristics = baseCharacteristics;
        this.game.characters[this.id].informations = baseInformations;

        switch (this.classe) {
            case 'vampire': {
                this.game.characters[this.id].informations[1].value = 'vampire';

                // points de vie
                this.game.characters[this.id].stats[0].current = 4;
                this.game.characters[this.id].stats[0].max = 4;

                // points d'esprit
                this.game.characters[this.id].stats[1].current = 4;
                this.game.characters[this.id].stats[1].max = 4;

                //vigueur
                this.game.characters[this.id].stats[2].current = 5;
                this.game.characters[this.id].stats[2].max = 10;

                //vampirisme
                this.game.characters[this.id].characteristics.push({
                    name: 'vampirisme',
                    value: 10,
                    color: '#cc0000'
                })

                break;
            }

            case 'zombie': {
                this.game.characters[this.id].informations[1].value = 'zombie'

                // points de vie
                this.game.characters[this.id].stats[0].current = 2;
                this.game.characters[this.id].stats[0].max = 2;

                // points d'esprit
                this.game.characters[this.id].stats[1].current = 6;
                this.game.characters[this.id].stats[1].max = 6;

                //vigueur
                this.game.characters[this.id].stats[2].current = 5;
                this.game.characters[this.id].stats[2].max = 5;
                
                this.game.characters[this.id].characteristics.push({
                    name: 'contagion',
                    value: 10,
                    color: '#45818e'
                })
                break;
            }

            case 'fantome': {
                this.game.characters[this.id].informations[1].value = 'fantome'

                // points de vie
                this.game.characters[this.id].stats[0].current = 0;
                this.game.characters[this.id].stats[0].max = 0;

                // points d'esprit
                this.game.characters[this.id].stats[1].current = 8;
                this.game.characters[this.id].stats[1].max = 8;

                //vigueur
                this.game.characters[this.id].stats[2].current = 5;
                this.game.characters[this.id].stats[2].max = 5;
                break;
            }

            case 'lycanthrope': {
                this.game.characters[this.id].informations[1].value = 'lycanthrope'

                // points de vie
                this.game.characters[this.id].stats[0].current = 4;
                this.game.characters[this.id].stats[0].max = 4;

                // points d'esprit
                this.game.characters[this.id].stats[1].current = 4;
                this.game.characters[this.id].stats[1].max = 4;

                //vigueur
                this.game.characters[this.id].stats[2].current = 5;
                this.game.characters[this.id].stats[2].max = 5;

                this.game.characters[this.id].informations.push({
                    name: 'transformation-modifier',
                    value: 0
                })
                this.game.characters[this.id].informations.push({
                    name: 'transformation-vigueur',
                    value: 0
                })

                this.game.characters[this.id].informations.push({
                    name: 'sous-classe',
                    value: 'loup'
                })
                break;
            }

            case 'sorcier': {
                this.game.characters[this.id].informations[1].value = 'sorcier'

                // points de vie
                this.game.characters[this.id].stats[0].current = 6;
                this.game.characters[this.id].stats[0].max = 6;

                // points d'esprit
                this.game.characters[this.id].stats[1].current = 2;
                this.game.characters[this.id].stats[1].max = 2;

                //vigueur
                this.game.characters[this.id].stats[2].current = 5;
                this.game.characters[this.id].stats[2].max = 5;


                this.game.characters[this.id].characteristics.push({
                    name: 'artisanat',
                    value: 10,
                    color: '#823abf'
                })
                break;
            }
        }
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`), this.game.characters[this.id]);
    }

    @Watch('sousClasse') async onSousClasseChange() {
        this.game.characters[this.id].informations.filter(i=>i.name=='sous-classe')[0].value = this.sousClasse;
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`), this.game.characters[this.id]);
    }

    loadImage(): void {
        const image = document.getElementById('pp') as HTMLImageElement;
        if (image) {
            const imageRef: StorageReference = refStorage(storage, `images/${this.game.id}/${this.id}.png`);
            getDownloadURL(imageRef).then(url => {
                image.src = url;
            }).catch(() => {
                console.log('no image');
            });
        }
    }

    image: any;

    setFile(event: any): void {
        this.image = event.target.files[0]
        console.log(this.image);
    }

    async upload(): Promise<void> {
        if (this.game) {
            const imgRef = storageRef(storage, `images/${this.game.id}/${this.id}.png`);
            uploadBytes(imgRef, this.image).then(() => {
                this.loadImage();
            }).catch(error => {
                console.log('error: ' + error);
            });
        }
    }

    characteristic(c: string): number {
        try {
            return this.game.characters[this.id].characteristics.filter(char => char.name === c)[0].value;
        } catch (e) {
            console.error(`could not find '${c}'`);
            return -1;
        }
    }

    information(c: string): string | number {
        try {
            return this.game.characters[this.id].informations.filter(char => char.name === c)[0].value;
        } catch (e) {
            console.error(`could not find '${c}'`);
            return -1;
        }
    }

    statCurrent(c: string): number {
        return this.game.characters[this.id].stats.filter(char => char.name === c)[0].current;
    }

    statMax(c: string): number {
        return this.game.characters[this.id].stats.filter(char => char.name === c)[0].max;
    }


    escapeKeyHandler(e: KeyboardEvent): void {
        if (e.key === 'Escape') this.closeModal();
    }

    openModal(category: string, stat: string): void {
        this.currentType = category;
        this.cssBlur = 'open';
        this.modal = true;
        this.currentModal = stat;
        if (category === 'stat') {
            this.modalText = this.game.characters[this.id].stats.filter(s => s.name === stat)[0].max.toString();
        } else if (category === 'char') {
            this.modalText = this.game.characters[this.id].characteristics.filter(s => s.name === stat)[0].value.toString();
        } else if (category === 'info') {
            this.modalText = this.game.characters[this.id].informations.filter(s => s.name === stat)[0].value.toString();
        } else {
            this.modalText = this.game.characters[this.id].name;
        }
        document.addEventListener('keydown', this.escapeKeyHandler);
        this.$nextTick(() => document.getElementById('input')?.focus());
    }

    closeModal(): void {
        this.cssBlur = '';
        this.modal = false;
        this.modalText = '';
        this.currentModal = '';
        this.currentType = '';
        document.removeEventListener('keydown', this.escapeKeyHandler);
    }

    async handleModal(): Promise<void> {
        if (this.currentType === 'stat') {
            this.game.characters[this.id].stats.filter(s => s.name === this.currentModal)[0].max = parseInt(this.modalText);
        } else if (this.currentType === 'char') {
            this.game.characters[this.id].characteristics.filter(s => s.name === this.currentModal)[0].value = parseInt(this.modalText);
        } else if (this.currentType === 'info') {
            this.game.characters[this.id].informations.filter(s => s.name === this.currentModal)[0].value = this.modalText;
        } else {
            this.game.characters[this.id].name = this.modalText;
        }
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`), this.game.characters[this.id]);
        this.closeModal();
    }

}
