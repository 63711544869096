
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Game} from '@/types/Interfaces';

@Component
export default class PadoMJTurnOrderComponent extends Vue {
    @Prop() private game !: Game;

    modal = false;

    created() {
        document.addEventListener('keyup', (event) => {
            if (event.key === 'Escape') {
                this.modal = false;
            }
        });
    }
}
