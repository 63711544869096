
import { Component, Prop, Vue } from 'vue-property-decorator';
import {AzuriaCharacter, Game} from '@/types/Interfaces';
import { get, ref, set } from 'firebase/database';
import { db } from '@/firebase';
import { Ability } from '@/types/Interfaces';

@Component
export default class AzrCharSheetAbilityComponent extends Vue {
    @Prop() private game !: Game;
    @Prop() private id !: number;

    modalEdit = false;
    modalEditName: string | undefined = '';
    modalCurrentAbility: string | undefined;

    modalCreate = false;
    modalCreateName: string | undefined = '';

    escapeKeyHandler(e: KeyboardEvent): void {
        if (e.key === 'Escape') {
            this.closeModalEdit();
            this.closeModalCreate();
            const inputEdit = document.getElementById('input-name-edit');
            const inputCreate = document.getElementById('input-name-create');
            if (inputEdit && inputCreate) {
                inputEdit.className = '';
                inputCreate.className = '';
            }
        }
    }

    openModalEdit(ability: string): void {
        if (this.modalCreate) this.closeModalCreate();
        this.modalEdit = true;
        this.modalCurrentAbility = ability;
        this.modalEditName = ability;
        document.addEventListener('keydown', this.escapeKeyHandler);
        this.$nextTick(() => document.getElementById('input-name-edit')?.focus());
    }

    closeModalEdit(): void {
        this.modalEdit = false;
        this.modalEditName = '';
        document.removeEventListener('keydown', this.escapeKeyHandler);
    }

    async handleModalEdit(): Promise<void> {
        let abilities: Ability[] | null = null;
        await get(ref(db, `/jdrs/${this.game.id}/characters/${this.id}/abilities`)).then(s => {
            if (s.val() !== null)
                abilities = Object.values(s.val());
        });
        if (abilities === null) abilities = [];
        const inputEdit = document.getElementById('input-name-edit');
        if (this.modalCurrentAbility && this.modalEditName && inputEdit) {
            if (!abilities.find(a => a.name === this.modalEditName) || this.modalCurrentAbility === this.modalEditName) {
                this.modalCurrentAbility = this.modalEditName;
                inputEdit.className = '';
                await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`), this.game.characters[this.id]);
                this.closeModalEdit();
            } else {
                inputEdit.className = 'error';
            }
        }
    }

    openModalCreate(): void {
        if (this.modalEdit) this.closeModalEdit();
        this.modalCreateName = '';
        this.modalCreate = true;
        document.addEventListener('keydown', this.escapeKeyHandler);
        this.$nextTick(() => document.getElementById('input-name-create')?.focus());
    }

    closeModalCreate(): void {
        this.modalCreate = false;
        document.removeEventListener('keydown', this.escapeKeyHandler);
    }

    async handleModalCreate(): Promise<void> {
        let abilities: string[] | null = null;
        await get(ref(db, `/jdrs/${this.game.id}/characters/${this.id}/abilities`)).then(s => {
            if (s.val() !== null)
                abilities = Object.values(s.val());
        });
        if (abilities === null) abilities = [];

        const input = document.getElementById('input-name-create');
        if (this.modalCreateName && input) {
            if (!abilities.find(a => a === this.modalCreateName)) {
                const ability = this.modalCreateName
                abilities = [...abilities, ability];
                await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}/abilities`), abilities);
                input.className = '';
                this.closeModalCreate();
            } else {
                input.className = 'error';
            }
        }
    }

    async removeAbility(ability: string): Promise<void> {
        const char = this.game.characters[this.id] as AzuriaCharacter;
        const abilities: string[] | null = char.abilities;
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}/abilities`), abilities.filter(a => ability !== a));
    }

}
