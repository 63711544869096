export class Spell {
    constructor(public name: string,
                public description: string,
                public level: number,
                public classe: string,
                public sousClasse: string,
            public roll: string) {}
}

export const spells = [
    new Spell("Frayeur", "Vous apparaissez brièvement devant une personne, la repoussant.", 3, "fantome", "", ""),
    new Spell("Apparition", "Choisissez une personne vivante pour entrer en contact avec elle. La personne ciblée pourra vous voir et vous entendre.", 5, "fantome", "", ""),
    new Spell("Tour de trafalgar", "Vous échangez de position avec la cible.", 6, "fantome", "", ""),
    new Spell("Yeux perçants", "Permet de déterminer le niveau de la créature morte-vivante ciblée.", 7, "fantome", "", ""),
    new Spell("Poigne spectrale", "Attire la cible au fantôme.", 9, "fantome", "", ""),
    new Spell("Train-fantôme", "Sur une cible dans votre environnement immédiat : la repousse et vous déplace par rapport à elle. La cible subit des dégâts.", 10, "fantome", "", ""),

    new Spell("Magie offensive", "Inflige 1d2 dégâts à une cible distante.", 1,  "sorcier", "", "1d2"),
    new Spell("Manipulation télékinétique", "Vous manipulez à distance l'objet / l'entité ciblée.", 2,  "sorcier", "", ""),
    new Spell("Magie curative", "Guérit 1d2 PV à la cible.", 3,  "sorcier", "", "1d2"),
    new Spell("Don de divination", "Votre esprit se projette dans l'espace-temps : vous avez temporairement accès à une vision du futur.", 4,  "sorcier", "", ""),
    new Spell("Magie offensive (2)", "Inflige 1d3 dégâts à une cible distante.", 5,  "sorcier", "", "1d3"),
    new Spell("Télépathie", "Vous lisez dans les pensées de l'entité ciblée.", 6,  "sorcier", "", ""),
    new Spell("Magie curative (2)", "Guérit 1d3 PV à la cible.", 7,  "sorcier", "1d3", ""),
    new Spell("Magie offensive (3)", "Inflige 1d3 + 1 dégâts à une cible distante.", 8,  "sorcier", "", "1d3 + 1"),
    new Spell("Vol", "Utilisez votre balai pour voler dans les airs.", 9,  "sorcier", "", ""),
    new Spell("Magie curative (3)", "Guérit 1d3 + 1 PV à la cible.", 10,  "sorcier", "", "1d3 + 1"),
    
    new Spell("Souffle de Morphée", "Votre haleine embrume l'esprit de la personne ciblée : vous endormez votre cible.", 3, "vampire", "", ""),
    new Spell("Émissaire nocturne", "Vous envoyez une chauve-souris en éclaireur.", 5, "vampire", "", ""),
    new Spell("Invisibilité", "Vous devenez temporairement invisible. En combat : l'effet dure 1d3 tours.", 7, "vampire", "", ""),
    new Spell("Nuée de chauves-souris", "Vous vous désagregez en une nuée de chauves-souris. Celle-ci vous permet de pouvoir léviter dans les airs.", 9, "vampire", "", ""),
    
    new Spell("Contagion", "Lancer la compétence du même nom : vous tentez de contaminer la cible avec votre virus. Vous lancez un jet de dé égal aux points de vie totaux de votre cible (exemple : si la cible a 6PV totaux, vous devez lancer 1d6). Sa valeur doit être supérieure aux points de vie actuels de la cible.", 1, "zombie", "", ""),
    new Spell("Âme fragmentée", "Si un membre du corps originel du zombie est coupé, celui-ci peut être manipulé à distance par son corps d'origine à tout moment.", 2, "zombie", "", ""),
    new Spell("Télépathie épidémique (stade 1)", "Permet au zombie de voir à travers les yeux d'une de ses cibles contaminées (quelle qu'elle soit et peu importe la distance).", 3, "zombie", "", ""),
    new Spell("Sang contaminé", "Au corps-à-corps, empoisonne l'adversaire qui blesse le zombie. Applique l'état “contaminé”.", 4, "zombie", "", ""),
    new Spell("Télépathie épidémique (stade 2)", "Permet au zombie de contrôler l'une de ses cibles contaminées (quelle qu'elle soit et peu importe la distance).", 5, "zombie", "", ""),
    new Spell("Synergie épidémique", "Si un ou plusieurs zombies contaminés se situent dans le même radius, partage les dégâts entre eux.", 6, "zombie", "", ""),
    new Spell("Amalgame", "Le zombie fusionne avec les cibles contaminées, permettant de créer temporairement (le temps d'un combat) un surzombie. Consomme les cibles dans l'état “contaminé”. Vampirise leurs PV & PE.", 7, "zombie", "", ""),

    new Spell("Cri de ralliement", "Vous communiquez votre position à toute entité se trouvant dans un rayon de 30 mètres.", 2,  "lycanthrope", "loup", ""),
    new Spell("Bond", "Vous vous projetez vers l’endroit ciblé. Cette action vous permet de sauter au-dessus d’obstacles importants ainsi qu’à des distances normalement impossibles à atteindre d’un saut. En combat, vous occasionnez des dégâts physiques en zone autour de vous.", 6,  "lycanthrope", "loup", ""),
    new Spell("Mâchoires d’acier", "Votre dentition aiguisée couplée à vos muscles de mâchoires vous permet de tordre, briser et mettre en pièces les objets les plus résistants. En combat : La cible doit lancer 1d20, et reporter son résultat à votre compétence de Force : Si son chiffre est supérieur au vôtre, elle parvient à s'extirper de votre emprise sans dommages; Si celui-ci est égal au vôtre, elle subit 2 points de dégâts; Si celui-ci est inférieur au vôtre, elle subit 2 points de dégâts, et se voit arracher le membre visé. Si vous avez ciblé son arme ou son objet de défense, celui-ci est brisé", 8, "lycanthtope", "loup", "1d20"),

    new Spell("Compagnon indéfectible", "En votre présence, le prochain jet de votre allié (au choix) obtiendra un bonus de 1.", 3,  "lycanthrope", "chien", ""),
    new Spell("Transmission de la rage", "Vous tentez de contaminer la cible avec le virus de la rage. Lancez un jet de dé égal aux points de vie totaux de votre cible (exemple : si la cible a 6PV totaux, vous devez lancer 1d6). Sa valeur doit être supérieure aux points de vie actuels de la cible. Si votre jet est réussi, vous lui faites subir un malus de :  1 point de Vie 1 point d’Esprit (s’il y a) 1 point de Vigueur Le malus est permanent, et ne se termine qu’à la fin du combat ou jusqu’à ce que la cible soit soignée.", 6,  "lycanthrope", "chien", ""),
    new Spell("Mâchoires d’acier", "Votre dentition aiguisée couplée à vos muscles de mâchoires vous permet de tordre, briser et mettre en pièces les objets les plus résistants. En combat : La cible doit lancer 1d20, et reporter son résultat à votre compétence de Force : Si son chiffre est supérieur au vôtre, elle parvient à s'extirper de votre emprise sans dommages; Si celui-ci est égal au vôtre, elle subit 2 points de dégâts; Si celui-ci est inférieur au vôtre, elle subit 2 points de dégâts, et se voit arracher le membre visé. Si vous avez ciblé son arme ou son objet de défense, celui-ci est brisé", 8, "lycanthtope", "chien", "1d20"),

    new Spell("Fourrure épaisse", "Vous gagnez 1 point de vie en bouclier.", 2,  "lycanthrope", "ours", ""),
    new Spell("Coup de patte", "Vous tentez d'assommer votre cible grâce à votre force. Lancez un jet de dé égal aux points de Vigueur totaux de votre cible (exemple : si la cible a 6 de Vigueur, vous devez lancer 1d6). Sa valeur doit être supérieure ou égale aux points de Vigueur actuels de la cible.Si votre jet est réussi, la cible perd connaissance. Si vous êtes en combat, la cible est neutralisée pour son prochain tour de combat et doit attendre le suivant pour jouer"    , 3,  "lycanthrope", "ours", ""),
    new Spell("Mâchoires d’acier", "Votre dentition aiguisée couplée à vos muscles de mâchoires vous permet de tordre, briser et mettre en pièces les objets les plus résistants. En combat : La cible doit lancer 1d20, et reporter son résultat à votre compétence de Force : Si son chiffre est supérieur au vôtre, elle parvient à s'extirper de votre emprise sans dommages; Si celui-ci est égal au vôtre, elle subit 2 points de dégâts; Si celui-ci est inférieur au vôtre, elle subit 2 points de dégâts, et se voit arracher le membre visé. Si vous avez ciblé son arme ou son objet de défense, celui-ci est brisé", 8, "lycanthtope", "ours", "1d20"),

    new Spell("Regard de malice", "Vous vous concentrez sur une cible en ligne de mire. Vous obtenez (au choix) des informations essentielles sur : Ses points de vie; ses points d’esprit; son nombre de points de Vigueur / ou à défaut sa jauge de points de sang.", 2,  "lycanthrope", "renard", ""),
    new Spell("Museau chapardeur", "La curiosité est un vilain défaut : vous fourrez votre nez dans les affaires des autres.Hors-combat : vous subtilisez un objet au choix dans l’inventaire de la cible, à condition d’être proche physiquement d’elle.En combat : Vous vampirisez 1d3 de points de Vigueur à la cible. Vous pouvez redistribuer les points volés entre vous et vos alliés. Attention : vous ne pouvez en donner qu’un par personne. Aussi, vous ne pouvez pas voler de points de Vigueur à une cible qui n’en a plus. Enfin, il n’est pas possible de relancer la technique “museau chapardeur” sur la même cible pendant la même partie.", 3,  "lycanthrope", "renard", ""),
    new Spell("Feinte", "Vous feintez votre action et renchérissez.  Hors-combat : si votre cible a déjà lancé un jet, quel qu’il soit, vous semez le trouble chez elle et la forcez à en retirer un. En combat : si votre adversaire a déjà lancé un jet d’Agilité / Robustesse, vous le forcez à en retirer un.  Note : À l’inverse de votre adversaire, vous n’avez pas à dépenser un nouveau point de Vigueur ni à lancer de dé.", 6, "lycanthrope", "renard", ""),
    new Spell("Mâchoires d’acier", "Votre dentition aiguisée couplée à vos muscles de mâchoires vous permet de tordre, briser et mettre en pièces les objets les plus résistants. En combat : La cible doit lancer 1d20, et reporter son résultat à votre compétence de Force : Si son chiffre est supérieur au vôtre, elle parvient à s'extirper de votre emprise sans dommages; Si celui-ci est égal au vôtre, elle subit 2 points de dégâts; Si celui-ci est inférieur au vôtre, elle subit 2 points de dégâts, et se voit arracher le membre visé. Si vous avez ciblé son arme ou son objet de défense, celui-ci est brisé", 8, "lycanthtope", "ours", "1d20"),
]



