export interface Ethnie {
    name: string;
    elements: Element[];
}

export enum Element {
    'air',
    'eau',
    'foudre',
    'neige',
    'glace',
    'vent',
    'feu',
    'brume',
    'magma',
    'roche',
    'terre',
    'sable',
    'poussiere',
    'null'
}

export const ethnies: Ethnie[] = [
    {
        name: 'elfe',
        elements: [Element.air, Element.eau]
    },
    {
        name: 'elfe des bois',
        elements: [Element.air, Element.eau]
    },
    {
        name: 'mylessian',
        elements: [Element.air, Element.foudre]
    },
    {
        name: 'fancorlin',
        elements: [Element.neige, Element.glace, Element.vent]
    },
    {
        name: 'sybillin',
        elements: []
    },
    {
        name: 'homme',
        elements: [Element.feu, Element.eau]
    },
    {
        name: 'nibeleens',
        elements: [Element.feu, Element.brume, Element.magma]
    },
    {
        name: 'orc',
        elements: [Element.feu, Element.roche, Element.terre]
    },
    {
        name: 'orman',
        elements: [Element.roche, Element.terre, Element.eau]
    },
    {
        name: 'orkarnak',
        elements: [Element.roche, Element.terre]
    },
    {
        name: 'marevespien',
        elements: [Element.eau, Element.brume]
    },
    {
        name: 'samien',
        elements: [Element.feu, Element.sable, Element.poussiere]
    }
]
