
import { Component, Prop, Vue } from 'vue-property-decorator';
import {Game, PadoCharacter} from '@/types/Interfaces';
import { get, ref, set } from 'firebase/database';
import { db } from '@/firebase';
import { Ability } from '@/types/Interfaces';

@Component
export default class PadoCharSheetComponent extends Vue {
    @Prop() private game !: Game;
    @Prop() private id !: number;

    modalEdit = false;
    modalEditName: string | undefined = '';
    modalEditRi: number | undefined = 0;
    modalCurrentAbility: Ability | undefined;

    modalCreate = false;
    modalCreateName: string | undefined = '';
    modalCreateRi: number | undefined = 0;

    escapeKeyHandler(e: KeyboardEvent): void {
        if (e.key === 'Escape') {
            this.closeModalEdit();
            this.closeModalCreate();
            const inputEdit = document.getElementById('input-name-edit');
            const inputCreate = document.getElementById('input-name-create');
            if (inputEdit && inputCreate) {
                inputEdit.className = '';
                inputCreate.className = '';
            }
        }
    }

    openModalEdit(ability: Ability): void {
        if (this.modalCreate) this.closeModalCreate();
        this.modalEdit = true;
        this.modalCurrentAbility = ability;
        this.modalEditName = ability.name;
        this.modalEditRi = ability.ri;
        document.addEventListener('keydown', this.escapeKeyHandler);
        this.$nextTick(() => document.getElementById('input-name-edit')?.focus());
    }

    closeModalEdit(): void {
        this.modalEdit = false;
        this.modalEditName = '';
        this.modalEditRi = 0;
        document.removeEventListener('keydown', this.escapeKeyHandler);
    }

    async handleModalEdit(): Promise<void> {
        let abilities: Ability[] | null = null;
        await get(ref(db, `/jdrs/${this.game.id}/characters/${this.id}/abilities`)).then(s => {
            if (s.val() !== null)
                abilities = Object.values(s.val());
        });
        if (abilities === null) abilities = [];
        const inputEdit = document.getElementById('input-name-edit');
        if (this.modalCurrentAbility && this.modalEditName && this.modalEditRi && inputEdit) {
            if (!abilities.find(a => a.name === this.modalEditName) || this.modalCurrentAbility.name === this.modalEditName) {
                this.modalCurrentAbility.name = this.modalEditName;
                this.modalCurrentAbility.ri = this.modalEditRi;
                inputEdit.className = '';
                await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`), this.game.characters[this.id]);
                this.closeModalEdit();
            } else {
                inputEdit.className = 'error';
            }
        }
    }

    openModalCreate(): void {
        if (this.modalEdit) this.closeModalEdit();
        this.modalCreateName = '';
        this.modalCreateRi = 0;
        this.modalCreate = true;
        document.addEventListener('keydown', this.escapeKeyHandler);
        this.$nextTick(() => document.getElementById('input-name-create')?.focus());
    }

    closeModalCreate(): void {
        this.modalCreate = false;
        document.removeEventListener('keydown', this.escapeKeyHandler);
    }

    async handleModalCreate(): Promise<void> {
        let abilities: Ability[] | null = null;
        await get(ref(db, `/jdrs/${this.game.id}/characters/${this.id}/abilities`)).then(s => {
            if (s.val() !== null)
                abilities = Object.values(s.val());
        });
        if (abilities === null) abilities = [];

        const input = document.getElementById('input-name-create');
        if (this.modalCreateName && this.modalCreateRi && input) {
            if (!abilities.find(a => a.name === this.modalCreateName)) {
                const ability = {
                    name: this.modalCreateName, ri: this.modalCreateRi
                }
                abilities = [...abilities, ability];
                await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}/abilities`), abilities);
                input.className = '';
                this.closeModalCreate();
            } else {
                input.className = 'error';
            }
        }
    }

    async removeAbility(ability: Ability): Promise<void> {
        const char = this.game.characters[this.id] as PadoCharacter;
        const abilities: Ability[] | null = char.abilities as Ability[];
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}/abilities`), abilities.filter(a => ability.name !== a.name));
    }

}
