// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import {getStorage} from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyBn3NsPvCDoWzrEktbDxAeDkTLtlfTaics',
    authDomain: 'lafabrique-3e34c.firebaseapp.com',
    databaseURL: 'https://lafabrique-3e34c-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'lafabrique-3e34c',
    storageBucket: 'lafabrique-3e34c.appspot.com',
    messagingSenderId: '510329356284',
    appId: '1:510329356284:web:fcb54c951b776aebb0bc79',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Access and export Firebase services
const auth = getAuth(app);
const db = getDatabase(app);
const storage = getStorage(app);

export { auth, db, storage};