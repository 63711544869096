import {Roll} from '@/types/Roll';
import {Character, JDR} from '@/types/Interfaces';
import {ref, set} from 'firebase/database';
import {db} from '@/firebase';
import {Spell} from '@/components/Kimakaan/spells';
import {Element} from '@/components/Kimakaan/ethnies';

export class KimRoll extends Roll {

    private spell?: Spell;

    constructor(stat: string, playerName: string, spell?: Spell) {
        super(stat, JDR.Kimakaan, playerName);
        if (spell) this.spell = spell;
        else this.spell = new Spell('', '', 0, '', Element.null, '');
    }

    public setText(): void {
        let res = `[${this.spell?.name !== '' ? this.spell?.name : this.stat}] : ${this.rolled}/${this.statValue} `;

        if (this.passed) res += 'Succès ';
        else res += 'Echec ';

        if (this.critic) res += 'critique';

        if (this.passed) res += '!';
        else res += '...';

        this.text = res;
    }

    async roll(character: Character, secret: boolean): Promise<void> {
        this.statValue = character.characteristics.filter(c => c.name === this.stat)[0].value;
        this.rolled = Math.floor(Math.random() * 20) + 1; //picks a random number between 1 and 20
        const vigueur = character.stats.filter(s => s.name === 'vigueur')[0].current;

        // reduce statValue if vigueur is negative
        if (vigueur < 0) {
            const reduce = Math.max(-15, vigueur as number * 3);
            this.statValue = Math.max(1, this.statValue + reduce);
        }

        // update vigueur
        const vigueurRef = ref(db, `/jdrs/${character.gameId}/characters/${character.playerId}/stats/1/current`);
        await (set(vigueurRef, vigueur as number - 1));


        let damage = this.rolled < this.statValue ? 0 : this.rolled - this.statValue;

        if (this.spell?.isMagic()) {
            // update vigueur
            const vigueurRef = ref(db, `/jdrs/${character.gameId}/characters/${character.playerId}/stats/1/current`);
            await (set(vigueurRef, vigueur as number - 1));

            let contrecoup = 0;
            const majeure = character.informations.filter(s => s.name === 'majeure')[0].value;
            const mineure = character.informations.filter(s => s.name === 'mineure')[0].value;
            const majeureLvl = Number(character.informations.filter(s => s.name === 'niveau majeure')[0].value);
            const mineureLvl = Number(character.informations.filter(s => s.name === 'niveau mineure')[0].value);
            let magieLvl = Number(character.informations.filter(s => s.name === 'experience')[0].value);
            magieLvl = magieLvl < 120 ? 0 : magieLvl < 330 ? 1 : magieLvl < 630 ? 2 : 3;

            if (character.informations[3].value === 'magie') {
                if (this.spell?.level === 0) contrecoup = 0;
                else if (this.spell?.level === 1) {
                    if (this.spell?.category === majeure && majeureLvl === 1) contrecoup = 1;
                    else if (this.spell?.category === mineure && mineureLvl === 1) contrecoup = 1;
                    else if (this.spell?.category === 'magie élémentaire' && magieLvl === 1) contrecoup = 1;
                    else contrecoup = 0;
                } else if (this.spell?.level === 2) {
                    if (this.spell?.category === majeure && majeureLvl === 2) contrecoup = 2;
                    else if (this.spell?.category === 'magie élémentaire' && magieLvl === 2) contrecoup = 2;
                    else contrecoup = 1;
                } else if (this.spell?.level === 3) {
                    if (this.spell?.category === majeure && majeureLvl === 3) contrecoup = 3;
                    else if (this.spell?.category === 'magie élémentaire' && magieLvl === 3) contrecoup = 3;
                    else contrecoup = 2;
                }
            } else if (character.informations[3].value === 'hybride') {
                if (this.spell?.level === 0) {
                    if (this.spell?.category === majeure && majeureLvl === 0) contrecoup = 1;
                    else if (this.spell?.category === mineure && mineureLvl === 0) contrecoup = 1;
                    else if (this.spell?.category === 'magie élémentaire' && magieLvl === 0) contrecoup = 1;
                    else contrecoup = 0;
                } else if (this.spell?.level === 1) {
                    if (this.spell?.category === majeure && majeureLvl === 1) contrecoup = 2;
                    else if (this.spell?.category === mineure && mineureLvl === 1) contrecoup = 2;
                    else if (this.spell?.category === 'magie élémentaire' && magieLvl === 1) contrecoup = 2;
                    else contrecoup = 1;
                } else if (this.spell?.level === 2) {
                    if (this.spell?.category === majeure && majeureLvl === 2) contrecoup = 3;
                    else if (this.spell?.category === mineure && mineureLvl === 2) contrecoup = 3;
                    else if (this.spell?.category === 'magie élémentaire' && magieLvl === 2) contrecoup = 3;
                    else contrecoup = 2;
                }
            } else if (character.informations[3].value === 'physique') {
                contrecoup = 2;
            }

            if (this.rolled > this.statValue) {
                if (contrecoup === 0) damage = 0;
                if (contrecoup === 1) damage = Math.max(1, Math.floor(damage / 3));
                if (contrecoup === 2) damage = Math.max(1, Math.floor(damage / 2));

                const hpRef = ref(db, `/jdrs/${character.gameId}/characters/${character.playerId}/stats/0/current`);
                await (set(hpRef, character.stats.filter(s => s.name === 'health')[0].current as number - damage));
            }
        }

        this.secret = secret;
        //Modifies passed and critic fields according to roll
        this.passed = (this.rolled && this.rolled <= this.statValue) as boolean;
        this.critic = (this.rolled && (this.rolled == 20 || this.rolled == 1)) as boolean;
        this.setText();
        if (this.spell?.isMagic() && damage !== 0) this.text += ` (${damage} hp)`;
        this.setCSSClass();
    }
}
