import { Roll } from '@/types/Roll';
import { JDR } from '@/types/Interfaces';
import { Character } from '@/types/Interfaces';

export class AzrRoll extends Roll {

    constructor(stat: string, playerName: string) {
        super(stat, JDR.Azuria, playerName);
    }

    async roll(character: Character, secret: boolean): Promise<void> {
        this.statValue = character.characteristics.filter(c => c.name === this.stat)[0].value;
        this.rolled = Math.floor(Math.random() * 100) + 1; //picks a random number between 1 and 20
        this.secret = secret;
        //Modifies passed and critic fields according to roll
        this.passed = (this.rolled && this.rolled <= this.statValue) as boolean;
        this.critic = (this.rolled && (this.rolled == 100 || this.rolled == 1)) as boolean;
        this.setText();
        this.setCSSClass();
    }
}
