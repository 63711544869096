
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Game, PadoCharacter} from '@/types/Interfaces';
import * as createjs from 'createjs-module';
import {ref, set} from 'firebase/database';
import {db, storage} from '@/firebase';
import {getDownloadURL, ref as refStorage, ref as storageRef, StorageReference} from 'firebase/storage';

@Component
export default class PadoMapComponent extends Vue {
    @Prop() private game !: Game;

    stage: createjs.Stage | null = null;
    backgroundBitmap: createjs.Bitmap | null = null;
    tokens: Map<number, { id: number, token: createjs.Container, clicked: boolean }> = new Map();

    async mounted(): Promise<void> {
        this.stage = new createjs.Stage('canvas');
        this.stage.update();
        await this.setBgImage();

        createjs.Ticker.addEventListener('tick', () => {
            this.tokens.forEach((token) => {
                const char = this.game.characters[token.id] as PadoCharacter;
                if (!token.clicked && !this.moving) {
                    token.token.x = this.getTokenStagePosition(char).x;
                    token.token.y = this.getTokenStagePosition(char).y;
                    this.stage?.update();
                }
            });
        });
        document.getElementById('canvas')?.addEventListener('wheel', (e) => {
            this.moving = true;
            const canvas = document.getElementById('canvas')?.getBoundingClientRect();
            if (this.backgroundBitmap && canvas) {
                const distanceFromXBorder = e.x - this.backgroundBitmap.x - canvas.x;
                const distanceFromYBorder = e.y - this.backgroundBitmap.y - canvas.y;
                const width = this.backgroundBitmap.getBounds().width * this.backgroundBitmap.scaleX;
                const height = this.backgroundBitmap.getBounds().height * this.backgroundBitmap.scaleY;
                const xRatio = distanceFromXBorder / width;
                const yRatio = distanceFromYBorder / height;
                if (e.deltaY > 0) {
                    this.changeScale(-0.05);
                } else {
                    this.changeScale(0.05);
                }
                e.preventDefault();
                const newWidth = this.backgroundBitmap.getBounds().width * this.backgroundBitmap.scaleX;
                const newHeight = this.backgroundBitmap.getBounds().height * this.backgroundBitmap.scaleY;
                this.backgroundBitmap.x = e.x - canvas.x - newWidth * xRatio;
                this.backgroundBitmap.y = e.y - canvas.y - newHeight * yRatio;
            }
            this.moving = false;
        });
    }

    async setBgImage(): Promise<void> {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        const imgRef = storageRef(storage, `images/${this.game.id}/maps/${this.game.map}`);
        await getDownloadURL(imgRef).then(url => {
            image.src = url;
        }).catch(err => {
            console.log(err);
        });
        image.onload = () => {
            const canvas = document.getElementById('canvas') as HTMLCanvasElement;
            canvas.width = window.innerWidth * 0.95;
            canvas.height = window.innerWidth * 0.4;
            window.addEventListener('resize', () => {
                canvas.width = window.innerWidth * 0.95;
                canvas.height = window.innerWidth * 0.4;
                this.stage?.update();
            });
            this.backgroundBitmap = new createjs.Bitmap(image);
            this.stage?.addChild(this.backgroundBitmap);
            this.stage?.update();
            this.initTokens();
            this.addDragListeners(this.backgroundBitmap);
        };
    }

    @Watch('game.map')
    handleMapChange(): void {
        this.stage?.removeAllChildren();
        this.setBgImage();
    }

    offset = {
        x: 0,
        y: 0,
    };
    moving = false;

    addDragListeners(bitmap: createjs.Bitmap): void {
        bitmap.addEventListener('mousedown', (event) => {
            this.moving = true;
            //@ts-ignore
            this.offset.x = event.stageX;
            //@ts-ignore
            this.offset.y = event.stageY;
        });
        bitmap.addEventListener('pressmove', (event) => {
            //@ts-ignore
            const x = event.stageX - this.offset.x;
            //@ts-ignore
            const y = event.stageY - this.offset.y;
            bitmap.x += x;
            bitmap.y += y;
            //@ts-ignore
            this.offset.x = event.stageX;
            //@ts-ignore
            this.offset.y = event.stageY;
            this.tokens.forEach((token) => {
                token.token.x += x;
                token.token.y += y;
            });
            this.stage?.update();
        });
        bitmap.addEventListener('pressup', () => {
            this.moving = false;
        });
    }

    tokensOffset = {
        x: 0,
        y: 0,
    };

    initTokens(): void {
        if (this.backgroundBitmap) {
            Object.values(this.game.characters).forEach((character) => {
                const char = character as PadoCharacter;
                const token = new createjs.Container();
                if (char.token) {
                    if (!char.token.display) {
                        char.token.display = false;
                        set(ref(db, `games/${this.game.id}/characters/${char.playerId}`), char);
                    }
                    if (!char.token.size) {
                        char.token.size = 1;
                        set(ref(db, `games/${this.game.id}/characters/${char.playerId}`), char);
                    }
                    if (char.token.display) {
                        try {
                            const imageRef: StorageReference = refStorage(storage, `images/${this.game.id}/${character.playerId}.png`);
                            const size = 60 * char.token.size;
                            //round border
                            const border = new createjs.Shape();
                            border.graphics.beginFill(char.token.color).drawCircle(0, 0, 1.15 * size / 2);
                            token.addChild(border);
                            getDownloadURL(imageRef).then(url => {
                                const image = document.createElement('img');
                                image.src = url;
                                image.crossOrigin = 'Anonymous';
                                image.onload = () => {
                                    const bitmap = new createjs.Bitmap(image);
                                    bitmap.x = -size / 2;
                                    bitmap.y = -size / 2;
                                    //crops the image to a centered square
                                    const maxSize = Math.max(bitmap.image.width, bitmap.image.height);
                                    const minSize = Math.min(bitmap.image.width, bitmap.image.height);
                                    bitmap.sourceRect = new createjs.Rectangle(
                                        bitmap.image.width > bitmap.image.height ? (maxSize - minSize) / 2 : 0,
                                        bitmap.image.width > bitmap.image.height ? 0 : (maxSize - minSize) / 2,
                                        minSize, minSize
                                    );
                                    //scale image to size
                                    bitmap.scaleX = size / bitmap.getBounds().width;
                                    bitmap.scaleY = size / bitmap.getBounds().height;
                                    //round image
                                    bitmap.mask = new createjs.Shape(new createjs.Graphics().f('#000').drawCircle(0, 0, size / 2));
                                    token.addChild(bitmap);
                                }
                            }).catch(() => {
                                const color = new createjs.Shape();
                                if (char.token) color.graphics.beginFill(char.token.color)
                                    .drawCircle(color.x = 0,
                                        color.y = 0,
                                        size / 2);
                                color.graphics.endFill();
                                token.addChild(color);
                                const letter = new createjs.Text(char.name.substr(0, 4), '20px Arial', '#FFF');
                                letter.textAlign = 'center';
                                letter.y -= 8;
                                token.addChild(letter);
                            });
                        } catch (error) {
                            console.log('error ' + error);
                        }
                    }
                }
                if (this.backgroundBitmap && char.token) {
                    token.addEventListener('mousedown', (event) => {
                        if (char) {
                            const token = this.tokens.get(char.playerId as unknown as number);
                            if (token) token.clicked = true;
                        }
                        //@ts-ignore
                        this.tokensOffset.x = event.stageX;
                        //@ts-ignore
                        this.tokensOffset.y = event.stageY;
                    });

                    token.addEventListener('pressmove', (event) => {
                        if (char.token) {
                            //@ts-ignore
                            token.x += event.stageX - this.tokensOffset.x;
                            //@ts-ignore
                            token.y += event.stageY - this.tokensOffset.y;
                            //@ts-ignore
                            this.tokensOffset.x = event.stageX;
                            //@ts-ignore
                            this.tokensOffset.y = event.stageY;
                            this.stage?.update();
                        } else {
                            console.log('Token is undefined');
                        }
                    });

                    token.addEventListener('pressup', () => {
                        if (char && char.token) {
                            const tokenMap = this.tokens.get(char.playerId as unknown as number);
                            if (tokenMap) {
                                tokenMap.clicked = false;
                                if (this.backgroundBitmap) {
                                    char.token.x = this.getTokenRealPosition(tokenMap.token).x;
                                    char.token.y = this.getTokenRealPosition(tokenMap.token).y;
                                }
                            }
                        }
                        set(ref(db, `/jdrs/${this.game.id}/characters/${char.playerId}/token`), char.token);
                    });

                    this.tokens.set(char.playerId as unknown as number, {
                        id: char.playerId as unknown as number,
                        token: token,
                        clicked: false
                    });
                    this.stage?.addChild(token);
                    this.stage?.update();
                }
            });
        }
    }

    changeScale(ratio: number): void {
        if (this.backgroundBitmap && this.backgroundBitmap.scaleX + ratio > 0.05) {
            this.backgroundBitmap.scaleX += ratio;
            this.backgroundBitmap.scaleY += ratio;
            this.tokens.forEach((token) => {
                const char = this.game.characters[token.id] as PadoCharacter;
                if (this.backgroundBitmap && char.token) {
                    token.token.x = this.getTokenStagePosition(char).x;
                    token.token.y = this.getTokenStagePosition(char).y;
                }
                token.token.scaleX += ratio;
                token.token.scaleY += ratio;
            });
            // this.stage?.update();
        }
    }

    getTokenStagePosition(char: PadoCharacter): { x: number, y: number } {
        if (this.backgroundBitmap && char.token) {
            return {
                x: (this.backgroundBitmap.scaleX * char.token.x) + this.backgroundBitmap.x,
                y: (this.backgroundBitmap.scaleY * char.token.y) + this.backgroundBitmap.y
            };
        }
        return {x: 0, y: 0};
    }

    getTokenRealPosition(token: createjs.Container): { x: number, y: number } {
        if (this.backgroundBitmap) {
            return {
                x: (token.x - this.backgroundBitmap.x) / this.backgroundBitmap.scaleX,
                y: (token.y - this.backgroundBitmap.y) / this.backgroundBitmap.scaleY
            };
        }
        return {x: 0, y: 0};
    }
}

