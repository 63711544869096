
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Game} from '@/types/Interfaces';
import {ref, set} from 'firebase/database';
import {db, storage} from '@/firebase';
import PadoCharSheetAbilityComponent from '@/components/ParceDomine/PadoCharSheetAbilityComponent.vue';
import {getDownloadURL, ref as storageRef, ref as refStorage, StorageReference, uploadBytes} from 'firebase/storage';
import AzrCharSheetAbilityComponent from "@/components/Azuria/AzrCharSheetAbilityComponent.vue";
import {ethnies} from "@/components/Kimakaan/ethnies";

@Component({
    components: {AzrCharSheetAbilityComponent, PadoCharSheetAbilityComponent},
})
export default class KimCharSheetComponent extends Vue {
    @Prop() private game !: Game;
    @Prop() private id !: number;

    modal = false;
    modalText = '';
    currentModal = '';
    currentType = '';

    ethnies = ethnies;
    ethnie = this.information("ethnie");

    cssBlur = '';

    classe = this.information("classe");

    majeure = this.information("majeure")
    mineure = this.information("mineure")

    majeureLvl = this.information("niveau majeure")
    mineureLvl = this.information("niveau mineure")

    voiesMagiques = ["sagesse", "ambition", "bravoure", "piété", "justice"];
    voiesPhysiques = ["force brute", "résistance", "dextérité", "célérité"];

    voies: string[] = []

    @Watch('classe')
    async onClasseChange(): Promise<void> {
        this.game.characters[this.id].informations[3].value = this.classe;
        this.switchVoies();
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`), this.game.characters[this.id]);
    }

    @Watch('majeure')
    async onMajeureChange(): Promise<void> {
        this.game.characters[this.id].informations[7].value = this.majeure;
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`), this.game.characters[this.id]);
    }

    @Watch('mineure')
    async onMineureChange(): Promise<void> {
        this.game.characters[this.id].informations[8].value = this.mineure;
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`), this.game.characters[this.id]);
    }

    @Watch('ethnie')
    async onEthnieChange(): Promise<void> {
        this.game.characters[this.id].informations[1].value = this.ethnie;
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`), this.game.characters[this.id]);
    }

    @Watch('majeureLvl')
    async onMajeureLvlChange(): Promise<void> {
        this.game.characters[this.id].informations[9].value = this.majeureLvl;
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`), this.game.characters[this.id]);
    }

    @Watch('mineureLvl')
    async onMineureLvlChange(): Promise<void> {
        this.game.characters[this.id].informations[10].value = this.mineureLvl;
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`), this.game.characters[this.id]);
    }

    switchVoies() {
        switch (this.classe) {
            case "magie":
                this.voies = [...this.voiesMagiques];
                break;
            case "physique":
                this.voies = [...this.voiesPhysiques];
                break;
            case "hybride":
                this.voies = this.voiesMagiques.concat(this.voiesPhysiques);
                break;
        }
    }

    mounted(): void {
        this.loadImage();
        this.switchVoies();
    }

    loadImage(): void {
        const image = document.getElementById('pp') as HTMLImageElement;
        if (image) {
            const imageRef: StorageReference = refStorage(storage, `images/${this.game.id}/${this.id}.png`);
            getDownloadURL(imageRef).then(url => {
                image.src = url;
            }).catch(() => {
                console.log('no image');
            });
        }
    }

    image: any;

    setFile(event: any): void {
        this.image = event.target.files[0]
        console.log(this.image);
    }

    async upload(): Promise<void> {
        if (this.game) {
            const imgRef = storageRef(storage, `images/${this.game.id}/${this.id}.png`);
            uploadBytes(imgRef, this.image).then(() => {
                this.loadImage();
            }).catch(error => {
                console.log('error: ' + error);
            });
        }
    }

    characteristic(c: string): number {
        return this.game.characters[this.id].characteristics.filter(char => char.name === c)[0].value;
    }

    information(c: string): string | number {
        return this.game.characters[this.id].informations.filter(char => char.name === c)[0].value;
    }

    statCurrent(c: string): number {
        return this.game.characters[this.id].stats.filter(char => char.name === c)[0].current;
    }

    statMax(c: string): number {
        return this.game.characters[this.id].stats.filter(char => char.name === c)[0].max;
    }


    escapeKeyHandler(e: KeyboardEvent): void {
        if (e.key === 'Escape') this.closeModal();
    }

    openModal(category: string, stat: string): void {
        this.currentType = category;
        this.cssBlur = 'open';
        this.modal = true;
        this.currentModal = stat;
        if (category === 'stat') {
            this.modalText = this.game.characters[this.id].stats.filter(s => s.name === stat)[0].max.toString();
        } else if (category === 'char') {
            this.modalText = this.game.characters[this.id].characteristics.filter(s => s.name === stat)[0].value.toString();
        } else if (category === 'info') {
            this.modalText = this.game.characters[this.id].informations.filter(s => s.name === stat)[0].value.toString();
        } else {
            this.modalText = this.game.characters[this.id].name;
        }
        document.addEventListener('keydown', this.escapeKeyHandler);
        this.$nextTick(() => document.getElementById('input')?.focus());
    }

    closeModal(): void {
        this.cssBlur = '';
        this.modal = false;
        this.modalText = '';
        this.currentModal = '';
        this.currentType = '';
        document.removeEventListener('keydown', this.escapeKeyHandler);
    }

    async handleModal(): Promise<void> {
        if (this.currentType === 'stat') {
            this.game.characters[this.id].stats.filter(s => s.name === this.currentModal)[0].max = parseInt(this.modalText);
        } else if (this.currentType === 'char') {
            this.game.characters[this.id].characteristics.filter(s => s.name === this.currentModal)[0].value = parseInt(this.modalText);
        } else if (this.currentType === 'info') {
            this.game.characters[this.id].informations.filter(s => s.name === this.currentModal)[0].value = this.modalText;
        } else {
            this.game.characters[this.id].name = this.modalText;
        }
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`), this.game.characters[this.id]);
        this.closeModal();
    }

}
