
/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
import { auth } from './firebase';
import { onAuthStateChanged, signInWithCustomToken, signOut, User } from 'firebase/auth';

@Component
export default class App extends Vue {

    inProgress = false;
    user: User | null = null;

    async created() {
        await onAuthStateChanged(auth, (user) => {
            if (user) {
                this.user = user;
                this.$store.commit('setUser', this.user);
            }
        });
    }

    async login() {
        this.authPopup().then((result: any) => {
            fetch(`/api/verify-auth?code=${result.token}`).then((response: Response) => {
                response.json().then((body: any) => {
                    signInWithCustomToken(auth, body.data.token).then((result) => {
                        this.user = result.user;
                        this.$store.commit('setUser', this.user);
                    });
                });
            });
        }).catch((error: any) => {
            console.error(error);
        });
    }

    async logout() {
        this.user = null;
        await signOut(auth);
        this.$store.state.user = null;
        await this.$router.push({ name: 'Home' }).catch(() => {
        });
    }

    async authPopup() {
        const self = this;
        return new Promise(function(resolve, reject) {
            const w = 600;
            const h = 600;
            const left = screen.width / 2 - w / 2;
            const top = screen.height / 2 - h / 2;
            const popup = window.open(
                '/api/discord-auth',
                'Discord Auth',
                `width=${w},height=${h},top=${top},left=${left}`,
            );
            const authInterval = window.setInterval(function() {
                try {
                    if (popup && popup.location.href.includes('/api/discord-auth?code=')) {
                        // L'utilisateur s'est connecté et a été redirigé
                        const token = new URLSearchParams(popup.location.search).get(
                            'code',
                        );
                        window.clearInterval(authInterval);
                        popup.close();
                        resolve({
                            status: 'ok',
                            token: token,
                        });
                    }
                } catch (e) {
                    // L'utilisateur est encore sur la page de connexion, il n'est donc pas possible d'accéder aux
                    // propriétés de la fenêtre
                }
                if (popup && popup.closed) {
                    setTimeout(function() {
                        self.inProgress = false;
                    }, 2000);
                    // La fênetre a été fermée
                    window.clearInterval(authInterval);
                    reject({
                        status: 'error',
                        message: `La connexion via Discord n'a pas abouti. Veuillez réessayer.`,
                    });
                }
            }, 100);
        });
    }
}
