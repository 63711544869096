
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Game, JDR} from '@/types/Interfaces';
import {Spell, spells} from "@/components/Kimakaan/spells";
import {ethnies} from "@/components/Kimakaan/ethnies";
import {IRoll} from "@/types/Roll";
import {get, ref, set} from "firebase/database";
import {db} from "@/firebase";
import {KimRoll} from "@/types/KimRoll";
import {NormalRoll} from "@/types/NormalRoll";

@Component
export default class KimSpellsComponent extends Vue {
    @Prop() private game !: Game;
    @Prop() private id !: number;

    spells: Spell[] = [];
    searchCategory = 'All';
    searchLevels = [true, true, true, true];

    infos = -1;

    mounted(): void {
        this.setupSpells();
    }

    setupSpells(): void {
        const majeure = this.game.characters[this.id].informations[7].value;
        const majeureLvl = this.game.characters[this.id].informations[9].value;
        const mineure = this.game.characters[this.id].informations[8].value;
        const mineureLvl = this.game.characters[this.id].informations[10].value;
        const ethnie = this.game.characters[this.id].informations[1].value;
        const elements = ethnies.find(eth => eth.name === ethnie)?.elements;
        const classe = this.game.characters[this.id].informations.find(info => info.name === 'classe')?.value;
        const xp = this.game.characters[this.id].informations[6].value;
        const elem1 = 120;
        const elem2 = 330;
        const elem3 = 630;

        this.spells = spells.filter(spell => {
            // BLOCK MEDIANS/MAJEURS
            if (spell.level >= 2) return false;

            if (spell.category === 'magie élémentaire' && elements?.includes(spell.element)) {
                if (spell.level == 0) return true;
                if (spell.level == 1 && xp >= elem1 && classe != "physique") return true;
                if (spell.level == 2 && xp >= elem2 && classe != "physique") return true;
                if (spell.level == 3 && xp >= elem3 && classe != "physique") return true;
            }

            // ajoute tous les sorts de base
            if (spell.category !== 'magie élémentaire' && spell.level === 0) return true;
            // ajoute les sorts majeurs et mineurs
            if (spell.category === majeure && spell.level <= majeureLvl) {
                return true;
            }
            return spell.category === mineure && spell.level <= mineureLvl;

        })

        this.spells = this.spells.filter(spell => {
            return (this.searchCategory === 'All' || spell.category.toLowerCase().includes(this.searchCategory.toLowerCase())) && this.searchLevels[spell.level];
        })
    }

    async addRoll(roll: IRoll): Promise<void> {
        if (!this.game.characters[this.id].rolls) {
            this.game.characters[this.id].rolls = [];
        }
        this.game.characters[this.id].rolls = [roll, ...this.game.characters[this.id].rolls];
        if (this.game.characters[this.id].rolls.length > 20) this.game.characters[this.id].rolls.pop();
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}/rolls`), this.game.characters[this.id].rolls);
    }

    async addGlobalRoll(roll: IRoll): Promise<void> {
        let globalRolls: IRoll[] | null = null;
        await get(ref(db, `/jdrs/${this.game.id}/rolls`)).then(snap => {
            globalRolls = snap.val();
        });
        if (globalRolls === null) globalRolls = [];
        globalRolls = [roll, ...globalRolls];
        globalRolls = globalRolls.slice(0, 100);
        await set(ref(db, `/jdrs/${this.game.id}/rolls`), globalRolls);
    }

    async roll(spell: Spell): Promise<void> {
        const vigueur = Number(this.game.characters[this.id].stats[1].current);
        if (vigueur < 1 && spell.isMagic()) return;
        if (this.game && this.game.characters[this.id]) {
            const roll: KimRoll = new KimRoll(spell.category, this.game.characters[this.id].name, spell);
            await roll.roll(this.game.characters[this.id], false);
            await this.addRoll(roll);
            await this.addGlobalRoll(roll);

            if (roll.passed && spell.damage !== "") {
                const damageRoll = new NormalRoll(false, JDR.Kimakaan, this.game.characters[this.id].name);
                await damageRoll.roll(spell.damage);
                await this.addRoll(damageRoll);
                await this.addGlobalRoll(damageRoll);
            }
        }
    }

    @Watch('searchCategory')
    onSearchChange(): void {
        this.setupSpells();
    }

    @Watch('searchLevels')
    onSearchLevelsChange(): void {
        this.setupSpells();
    }

    @Watch(`game.characters`)
    onGameChange(): void {
        this.setupSpells();
    }
}
