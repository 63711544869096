
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Game} from '@/types/Interfaces';
import {IRoll, Roll} from '@/types/Roll';
import {get, ref, set} from 'firebase/database';
import {db} from '@/firebase';
import {DamageRoll} from '@/types/DamageRoll';
import PadoNPCComponent from './PadoNPCComponent.vue';
import {NormalRoll} from '@/types/NormalRoll';
import PadoMapComponent from '@/components/ParceDomine/PadoMapComponent.vue';
import PadoMJTurnOrderComponent from '@/components/ParceDomine/PadoMJTurnOrderComponent.vue';

@Component({
    components: {PadoMJTurnOrderComponent, PadoMapComponent, PadoNPCComponent},
})

export default class PadoMJComponent extends Vue {
    @Prop() private game !: Game;

    critic = false;
    secret = false;
    npcs: boolean[] = [];

    normalRollText = '';

    created(): void {
        this.npcs = Array(Object.values(this.game.characters).filter(char => {
            return char.isNPC;
        }).length);
        for (let i = 0; i < this.npcs.length; i++) {
            this.npcs[i] = false;
        }
    }

    async rollNormal(): Promise<void> {
        const roll = new NormalRoll(this.secret, this.game.type, 'MJ');
        roll.roll(this.normalRollText);

        let mjRolls: IRoll[] | null = null;
        await get(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}/rolls`)).then(snap => {
            mjRolls = snap.val();
        });
        if (mjRolls === null) mjRolls = [];
        mjRolls = [roll, ...mjRolls];
        mjRolls = mjRolls.slice(0, 25);
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}/rolls`), mjRolls);

        let globalRolls: IRoll[] | null = null;
        await get(ref(db, `/jdrs/${this.game.id}/rolls`)).then(snap => {
            globalRolls = snap.val();
        });
        if (globalRolls === null) globalRolls = [];
        globalRolls = [roll, ...globalRolls];
        globalRolls = globalRolls.slice(0, 100);
        await set(ref(db, `/jdrs/${this.game.id}/rolls`), globalRolls);
    }

    async rollDamage(type: string): Promise<void> {
        if (this.game) {
            const roll: DamageRoll = new DamageRoll(type, 'MJ');
            roll.roll(null, this.secret);
            let mjRolls: Roll[] | null = null;
            await get(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}/rolls`)).then(snap => {
                mjRolls = snap.val();
            });
            if (mjRolls === null) mjRolls = [];
            mjRolls = [roll, ...mjRolls];
            mjRolls = mjRolls.slice(0, 25);
            await set(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}/rolls`), mjRolls);

            let globalRolls: Roll[] | null = null;
            await get(ref(db, `/jdrs/${this.game.id}/rolls`)).then(snap => {
                globalRolls = snap.val();
            });
            if (globalRolls === null) globalRolls = [];
            globalRolls = [roll, ...globalRolls];
            globalRolls = globalRolls.slice(0, 100);
            await set(ref(db, `/jdrs/${this.game.id}/rolls`), globalRolls);
        }
    }

    getGroups(): { name: string, npcs: any[] }[] {
        const ret: { name: string, npcs: any[] }[] = [];
        this.game.groups.forEach(group => {
            const pnjs = Object.values(this.game.characters).filter(char => {
                return char.isNPC && char.category === group && char.isDisplayed;
            });
            if (pnjs.length > 0) ret.push({name: group, npcs: pnjs});
        });
        return ret;
    }

    @Watch('game.turnOrder') onTurnOrderChange(): void {
        if (this.game.turnOrder.isStarted && this.game.turnOrder.isActive) {
            this.npcs.forEach((value, index) => {
                if (value) {
                    this.npcs[index] = false;
                }
            });
            this.npcs[parseInt(this.game.turnOrder.characters[0].id)] = true;
        }
    }
}
