
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Game} from '@/types/Interfaces';
import {ref, set} from 'firebase/database';
import {db} from '@/firebase';

interface Inventory {
    itemSlots : number;
    items : InventoryItem[];
}

interface InventoryItem {
    name: string;
    quantity: number;
    weight: number;
}

@Component
export default class KimPlayerInventoryComponent extends Vue {
    @Prop() private game !: Game;
    @Prop() private characterId !: string;

    addItemModal = false;
    modalCreateName = '';
    modalCreateQuantity = 1;
    modalCreateWeight = 1;

    created(): void {
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                this.addItemModal = false;
            }
        });
    }

    async addItem(): Promise<void> {
        let inventory: Inventory = this.getInventory();

        //because v-model changes number to string :(
        this.modalCreateQuantity = this.modalCreateQuantity as number;
        this.modalCreateWeight = this.modalCreateWeight as number;

        const item: InventoryItem = {
            name: this.modalCreateName,
            quantity: this.modalCreateQuantity as number,
            weight: this.modalCreateWeight as number,
        };
        inventory.items ? inventory.items.push(item) : inventory.items = [item];
        if (this.inventoryCurrentSize() <= inventory.itemSlots) {
            await this.setInventory(inventory);
        } else {
            inventory.items.pop();
        }
        await this.setInventory(inventory);
        this.addItemModal = false;
        this.modalCreateName = '';
        this.modalCreateQuantity = 1;
        this.modalCreateWeight = 1;
    }

    async changeQuantity(id: number, modifier: number): Promise<void> {
        const inventory = this.getInventory();
        const item = inventory.items[id];
        item.quantity += (modifier as number);
        if (this.inventoryCurrentSize() <= inventory.itemSlots) {
            await this.setInventory(inventory);
        } else {
            item.quantity -= modifier;
        }
    }

    async removeItem(item: InventoryItem): Promise<void> {
        let inventory: Inventory = this.getInventory();
        inventory.items = inventory.items.filter(i => i !== item);
        await this.setInventory(inventory);
    }

    inventoryCurrentSize(): number {
        if (!this.getInventory().items) {
            return 0;
        }
        return this.getInventory().items.reduce((acc, item) => acc + item.weight * item.quantity, 0);
    }

    getInventory(): Inventory {
        // @ts-ignore
        return Object.values(this.game.characters).filter(c => c.playerId === this.characterId)[0].inventory;
    }

    async setInventory(inventory: Inventory): Promise<void> {
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.characterId}/inventory`), inventory);
    }
}
