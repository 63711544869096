import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import Home from "../views/Home.vue";
import Jdr from "@/views/Jdr.vue";
import Games from "@/views/Games.vue";
import Game from "@/views/Game.vue";
import CharSheet from "@/views/CharSheet.vue";
import EditPage from '@/views/EditPage.vue';
import CreateGame from '@/views/CreateGame.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/jdr",
        name: "Jdr",
        component: Jdr
    },
    {
        path: "/games",
        name: "Games",
        component: Games
    },
    {
        path: "/games/:gameId",
        name: "Game",
        component: Game,
    },
    {
        path: "/games/:gameId/edit",
        name: "EditPage",
        component: EditPage
    },
    {
        path: "/games/:gameId/:charId",
        name: "CharSheet",
        component: CharSheet,
    },
    {
        path: "/createGame",
        name: "CreateGame",
        component: CreateGame
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
