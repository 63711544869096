
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Game, Inventory, InventoryItem } from '@/types/Interfaces';
import { ref, set } from 'firebase/database';
import { db } from '@/firebase';
import { PadoRoll } from '@/types/PadoRoll';

@Component
export default class PadoPlayerInventoryComponent extends Vue {
    @Prop() private game !: Game;

    addItemModal = false;
    modalCreateName = '';
    modalCreateIsWeapon = false;
    modalCreateAmmunition = 0;
    modalCreateQuantity = 0;

    created(): void {
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                this.addItemModal = false;
            }
        });
    }

    async addItem(): Promise<void> {
        let inventory: Inventory = this.getInventory();

        //because v-model changes number to string :(
        this.modalCreateAmmunition = this.modalCreateAmmunition as number;
        this.modalCreateQuantity = this.modalCreateQuantity as number;

        const item: InventoryItem = {
            name: this.modalCreateName,
            quantity: this.modalCreateQuantity as number,
            isWeapon: this.modalCreateIsWeapon,
            ammunition: this.modalCreateAmmunition as number,
        };
        inventory.items ? inventory.items.push(item) : inventory.items = [item];
        await this.setInventory(inventory);
        this.addItemModal = false;
        this.modalCreateName = '';
        this.modalCreateIsWeapon = false;
        this.modalCreateAmmunition = 0;
        this.modalCreateQuantity = 0;
    }

    async useWeapon(id: number): Promise<void> {
        const inventory = this.getInventory();
        if (inventory.items[id].ammunition > 0) {
            inventory.items[id].ammunition--;
            await this.setInventory(inventory);
            const roll: PadoRoll = new PadoRoll('volonte', this.game.characters[this.$store.state.user.uid].name);
            await roll.roll(this.game.characters[this.$store.state.user.uid], false);
            await this.game.addRoll(roll);
        }
    }

    async changeQuantity(id: number, modifier: number): Promise<void> {
        const inventory = this.getInventory();
        const item = inventory.items[id];
        if (item.isWeapon ? item.ammunition + modifier >= 0 : item.quantity + modifier >= 0) {
            item.isWeapon ? item.ammunition += modifier : item.quantity += modifier;
            await this.setInventory(inventory);
        }
    }

    async removeItem(item: InventoryItem): Promise<void> {
        let inventory: Inventory = this.getInventory();
        inventory.items = inventory.items.filter(i => i !== item);
        await this.setInventory(inventory);
    }

    getInventory(): Inventory {
        // @ts-ignore
        return Object.values(this.game.characters).filter(c => c.playerId === this.$store.state.user.uid)[0].inventory;
    }

    async setInventory(inventory: Inventory): Promise<void> {
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}/inventory`), inventory);
    }
}
