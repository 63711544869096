import {IRoll} from '@/types/Roll';
import {JDR} from '@/types/Interfaces';

export class NormalRoll implements IRoll {
    secret: boolean | undefined;
    CSSClass: string | undefined;
    text: string | undefined;

    timestamp: string;
    readonly jdr: JDR;
    readonly playerName: string;
    rolled: number;

    constructor(secret: boolean, jdr: JDR, playerName: string) {
        this.secret = secret;
        this.timestamp = Date.now().toString();
        this.jdr = jdr;
        this.playerName = playerName;
        this.rolled = -1;
    }

    roll(value: string): void {
        if (value.includes('d')) {
            const values: string[] = value.split('d');
            const number = parseInt(values[0]);
            let diceValue = parseInt(values[1]);
            let modifier = 0;
            if (values[1].includes('+')) {
                diceValue = parseInt(values[1].split(' + ')[0]);
                modifier = parseInt(values[1].split(' + ')[1]);
            }
            const rolls = [];
            for (let i = 0; i < number; i++) {
                rolls.push(Math.floor(Math.random() * diceValue + 1));
            }

            this.text = number + 'd' + diceValue +  ' [';
            for (let i = 0; i < rolls.length - 1; i++) {
                this.text += `${rolls[i]}, `;
            }
            const sum = rolls.reduce((a,b) => a+b, 0) + modifier;
            this.rolled = sum;
            this.text += `${rolls[rolls.length - 1]}] `;
            if (modifier != 0) this.text += ' + ' + modifier;
            this.text += ` = ${sum}`;
        } else {
            this.text = value;
        }

        this.CSSClass = 'succes'
    }
}
