
import { Component, Vue } from 'vue-property-decorator';
import { onValue, ref } from 'firebase/database';
import { db } from '@/firebase';
import PadoPlayerComponent from '@/components/ParceDomine/PadoPlayerComponent.vue';
import PadoMJComponent from '@/components/ParceDomine/PadoMJComponent.vue';
import { Game, JDR } from '@/types/Interfaces';
import DabMJComponent from '@/components/DameBlanche/DabMJComponent.vue';
import DabPlayerComponent from '@/components/DameBlanche/DabPlayerComponent.vue';
import AzrPlayerComponent from "@/components/Azuria/AzrPlayerComponent.vue";
import AzrMJComponent from "@/components/Azuria/AzrMJComponent.vue";
import KimMJComponent from "@/components/Kimakaan/KimMJComponent.vue";
import KimPlayerComponent from "@/components/Kimakaan/KimPlayerComponent.vue";
import LotdMJComponent from "@/components/LandOfTheDread/LotdMJComponent.vue";
import LotdPlayerComponent from "@/components/LandOfTheDread/LotdPlayerComponent.vue";

@Component({
    components: {
        LotdMJComponent,
        LotdPlayerComponent,
        KimPlayerComponent,
        KimMJComponent,
        AzrMJComponent,
        AzrPlayerComponent, DabPlayerComponent, DabMJComponent, PadoPlayerComponent, PadoMJComponent },
})

export default class GameVue extends Vue {
    game: Game | null = null;
    ParceDomine: JDR = JDR.ParceDomine;
    DameBlanche: JDR = JDR.DameBlanche;
    Kimakaan: JDR = JDR.Kimakaan;
    LandOfTheDread: JDR = JDR.LandOfTheDread;

    Azuria: JDR = JDR.Azuria;
    async created(): Promise<void> {
        const id = this.$route.params.gameId;
        try {
            const jdrsRef = ref(db, `/jdrs/${id}`);
            await onValue(jdrsRef, (snapshot) => {
                this.game = new Game(snapshot.val());
            });
        } catch (error) {
            console.log('error : ' + error);
        }
    }
}
