
import {Component, Vue} from 'vue-property-decorator';
import {Game, JDR, Character} from '@/types/Interfaces';
import {onValue, ref, remove, set} from 'firebase/database';
import {db, storage} from '@/firebase';
import {listAll, ref as storageRef, uploadBytes} from 'firebase/storage';

@Component
export default class EditPage extends Vue {
    game: Game | null = null;

    ParceDomine: JDR = JDR.ParceDomine;
    files: string[] = [];
    currentMap = '';

    async changeCategory(): Promise<void> {
        await set(ref(db, `/jdrs/${this.$route.params.gameId}`), this.game);
    }

    async created(): Promise<void> {
        try {
            const jdrsRef = ref(db, `/jdrs/${this.$route.params.gameId}`);
            onValue(jdrsRef, (snapshot) => {
                this.game = snapshot.val();
                if (this.game && this.game.map) this.currentMap = this.game?.map;
            });

            if (this.game && this.game.map) {
                const listRef = storageRef(storage, `images/${this.$route.params.gameId}/maps`);
                listAll(listRef).then((res) => {
                    this.files = res.items.map(item => item.name);
                }).catch((error) => {
                    console.log(error);
                });
            }
            console.log(this.game);
        } catch (error) {
            console.log('error : ' + error);
        }
    }

    async updateCharacter(character: Character): Promise<void> {
        await set(ref(db, `/jdrs/${this.$route.params.gameId}/characters/${character.playerId}`), character);
    }

    characterName = '';
    isNPC = true;
    playerId = '';

    addCharacter(): void {
        if (this.game) {
            let obj;
            if (this.game.type === JDR.ParceDomine) {
                obj = {
                    name: this.characterName,
                    isNPC: this.isNPC,
                    gameId: this.game?.id,
                    playerId: this.playerId,
                    token: {
                        x: 0,
                        y: 0,
                        color: 'rgba(0, 0, 0, 0.8)',
                    },
                    category: '',
                    stats: [
                        {
                            name: 'health',
                            current: 100,
                            max: 100
                        },
                        {
                            name: 'ri',
                            current: 250,
                            max: 250
                        },
                        {
                            name: 'vigueur',
                            current: 10,
                            max: 10
                        },
                        {
                            name: 'mental',
                            current: 10,
                            max: 10
                        }
                    ],
                    characteristics: [
                        {
                            name: 'force',
                            value: 10,
                            color: 'green'
                        },
                        {
                            name: 'agilite',
                            value: 10,
                            color: 'green'
                        },
                        {
                            name: 'rapidite',
                            value: 10,
                            color: 'green'
                        },
                        {
                            name: 'parade',
                            value: 10,
                            color: 'green'
                        },
                        {
                            name: 'intuition',
                            value: 10,
                            color: 'purple'
                        },
                        {
                            name: 'volonte',
                            value: 10,
                            color: 'purple'
                        }
                    ],
                    informations: [
                        {
                            name: 'experience',
                            value: 0
                        },
                        {
                            name: 'age',
                            value: 18
                        },
                        {
                            name: 'taille',
                            value: '1.80'
                        },
                        {
                            name: 'descriptionPhysique',
                            value: ''
                        },
                        {
                            name: 'realName',
                            value: this.characterName
                        },
                        {
                            name: 'poids',
                            value: '70'
                        },
                        {
                            name: 'biographie',
                            value: ''
                        },
                        {
                            name: 'categorieLueur',
                            value: ''
                        },
                        {
                            name: 'sousCategorieLueur',
                            value: ''
                        },
                        {
                            name: 'robustesse',
                            value: 10
                        },
                        {
                            name: 'physique',
                            value: 10
                        },
                        {
                            name: 'psychisme',
                            value: 10
                        },
                        {
                            name: 'lueur',
                            value: 3
                        },
                        {
                            name: 'descriptionLueur',
                            value: ''
                        }
                    ]
                };
            } else if (this.game.type === JDR.Azuria) {
                obj = {
                    name: this.characterName,
                    isNPC: this.isNPC,
                    gameId: this.game?.id,
                    playerId: this.playerId,
                    token: {
                        x: 0,
                        y: 0,
                        color: 'rgba(0, 0, 0, 0.8)',
                    },
                    category: '',
                    inventory: {
                        itemSlots: 10,
                        items: []
                    },
                    stats: [
                        {
                            name: 'health',
                            current: 30,
                            max: 30
                        },
                    ],
                    characteristics: [
                        {
                            name: 'agilite',
                            value: 10,
                            color: '#08086A'
                        },
                        {
                            name: 'constitution',
                            value: 10,
                            color: '#08086A'
                        },
                        {
                            name: 'force',
                            value: 10,
                            color: '#08086A'
                        },
                        {
                            name: 'intelligence/logique',
                            value: 10,
                            color: '#ECA101'
                        },
                        {
                            name: 'social',
                            value: 10,
                            color: '#ECA101'
                        },
                        {
                            name: 'temperance',
                            value: 10,
                            color: '#ECA101'
                        },
                        {
                            name: 'tir',
                            value: 10,
                            color: '#08086A'
                        },
                        {
                            name: 'volonte',
                            value: 10,
                            color: '#ECA101'
                        },
                    ],
                    informations: [
                        {
                            name: 'biographie',
                            value: ''
                        },
                        {
                            name: 'race',
                            value: 'automate'
                        },
                        {
                            name: 'ameliorations',
                            value: ''
                        },
                        {
                            name: 'seuil de fatigue des ameliorations',
                            value: 3
                        },
                        {
                            name: 'Réput. Mondaine',
                            value: 10
                        },
                    ]
                };
            } else if (this.game.type === JDR.Kimakaan) {
                obj = {
                    name: this.characterName,
                    isNPC: this.isNPC,
                    gameId: this.game?.id,
                    playerId: this.playerId,
                    token: {
                        x: 0,
                        y: 0,
                        color: 'rgba(0, 0, 0, 0.8)',
                    },
                    category: '',
                    inventory: {
                        itemSlots: 10,
                        items: []
                    },
                    stats: [
                        {
                            name: 'health',
                            current: 30,
                            max: 30
                        },
                        {
                            name: 'vigueur',
                            current: 12,
                            max: 12
                        }
                    ],
                    characteristics: [
                        {
                            name: 'force brute',
                            value: 10,
                            color: '#87f8a8'
                        },
                        {
                            name: 'résistance',
                            value: 10,
                            color: '#87f8a8'
                        },
                        {
                            name: 'dextérité',
                            value: 10,
                            color: '#87f8a8'
                        },
                        {
                            name: 'célérité',
                            value: 10,
                            color: '#87f8a8'
                        },
                        {
                            name: 'alchimie',
                            value: 10,
                            color: '#ffe599'
                        },
                        {
                            name: 'forgeage',
                            value: 10,
                            color: '#ffe599'
                        },
                        {
                            name: 'récolte',
                            value: 10,
                            color: '#ffe599'
                        },
                        {
                            name: 'magie élémentaire',
                            value: 10,
                            color: '#8DD5F4',
                        },
                        {
                            name: 'sagesse',
                            value: 10,
                            color: '#8DD5F4',
                        },
                        {
                            name: 'ambition',
                            value: 10,
                            color: '#8DD5F4',
                        },
                        {
                            name: 'bravoure',
                            value: 10,
                            color: '#8DD5F4',
                        },
                        {
                            name: 'piété',
                            value: 10,
                            color: '#8DD5F4',
                        },
                        {
                            name: 'justice',
                            value: 10,
                            color: '#8DD5F4',
                        },
                    ],
                    informations: [
                        {
                            name: 'biographie',
                            value: ''
                        },
                        {
                            name: 'ethnie',
                            value: 'elfe'
                        },
                        {
                            name: 'religion',
                            value: ''
                        },
                        {
                            name: 'classe',
                            value: 'physique'
                        },
                        {
                            name: 'taille',
                            value: 170
                        },
                        {
                            name: 'description physique',
                            value: ''
                        },
                        {
                            name: 'experience',
                            value: 0
                        },
                        {
                            name: 'majeure',
                            value: 'résistance'
                        },
                        {
                            name: 'mineure',
                            value: 'dextérité'
                        },
                        {
                            name: 'niveau majeure',
                            value: 0
                        },
                        {
                            name: 'niveau mineure',
                            value: 0
                        },
                    ]
                };
            } else if (this.game.type === JDR.LandOfTheDread) {
                obj = {
                    name: this.characterName,
                    isNPC: this.isNPC,
                    gameId: this.game?.id,
                    playerId: this.playerId,
                    token: {
                        x: 0,
                        y: 0,
                        color: 'rgba(0, 0, 0, 0.8)',
                        size: 1,
                    },
                    category: '',
                    inventory: {
                        itemSlots: 10,
                        items: []
                    },
                    stats: [
                        {
                            name: 'health',
                            current: 30,
                            max: 30
                        },
                        {
                            name: 'spirit',
                            current: 5,
                            max: 5,
                        },
                        {
                            name: 'vigueur',
                            current: 12,
                            max: 12
                        }
                    ],
                    characteristics: [
                        {
                            name: 'force',
                            value: 10,
                            color: '#87f8a8'
                        },
                        {
                            name: 'agilité',
                            value: 10,
                            color: '#87f8a8'
                        },
                        {
                            name: 'rapidité',
                            value: 10,
                            color: '#87f8a8'
                        },
                        {
                            name: 'robustesse',
                            value: 10,
                            color: '#87f8a8'
                        },
                        {
                            name: 'perception',
                            value: 10,
                            color: '#ffe599'
                        },
                        {
                            name: 'visée',
                            value: 10,
                            color: '#ffe599'
                        },
                    ],
                    informations: [
                        {
                            name: 'nom',
                            value: ''
                        },
                        {
                            name: 'classe',
                            value: 'vampire'
                        },
                        {
                            name: 'biographie',
                            value: ''
                        },
                        {
                            name: 'niveau',
                            value: 1
                        },
                        {
                            name: 'sous-classe',
                            value: 'loup'
                        }
                    ]
                };
            }


            if (obj) {
                if (this.isNPC) {
                    obj.playerId = `${this.game.npcCount}`;
                    set(ref(db, `/jdrs/${this.game.id}/npcCount`), this.game.npcCount + 1);
                }
                set(ref(db, `/jdrs/${this.game.id}/characters/${obj.playerId}`), obj);
            } else {
                console.error('Error: can\'t create character');
            }
        }
    }

    changeMap(): void {
        if (this.game) {
            this.game.map = this.currentMap;
            set(ref(db, `/jdrs/${this.$route.params.gameId}`), this.game);
        }
    }

    image: any;

    setFile(event: any): void {
        this.image = event.target.files[0];
    }

    async upload(): Promise<void> {
        if (this.game) {
            const imgRef = storageRef(storage, `images/${this.game.id}/maps/${this.image.name}`);
            const span = document.getElementById('validation-text');
            uploadBytes(imgRef, this.image).then(() => {
                this.files.push(this.image.name);
                if (span) span.innerHTML = 'Uploaded !';
            }).catch(error => {
                if (span) span.innerHTML = 'Error : ' + error;
            });
        }
    }

    groupInput = '';

    async addGroup(): Promise<void> {
        if (this.game) {
            if (this.game.groups) {
                if (this.game.groups.includes(this.groupInput)) return;
                this.game.groups.push(this.groupInput);
            } else {
                this.game.groups = [this.groupInput];
            }
            set(ref(db, `/jdrs/${this.$route.params.gameId}`), this.game);
        }
    }

    modalDelete = false;
    modalDeleteText = '';
    modalDeleteCharacter: Character | undefined = undefined;

    deletePNJ(character: Character): void {
        this.modalDelete = true;
        this.modalDeleteText = character.name;
        this.modalDeleteCharacter = character;
    }

    async confirmDeleteCharacter(): Promise<void> {
        if (this.game && this.modalDeleteCharacter) {
            this.modalDelete = false;
            await remove(ref(db, `/jdrs/${this.game.id}/characters/${this.modalDeleteCharacter.playerId}`));
        }
    }

    modalDeleteGroup = false;
    modalDeleteGroupText = '';

    deleteGroup(group: string): void {
        this.modalDeleteGroup = true;
        this.modalDeleteGroupText = group;
    }

    async confirmDeleteGroup(): Promise<void> {
        if (this.game) {
            this.modalDelete = false;
            const groups = this.game.groups.filter(g => g !== this.modalDeleteGroupText);
            for (const character of Object.values(this.game.characters)) {
                if (this.game && character.category === this.modalDeleteGroupText) {
                    character.category = '';
                    await set(ref(db, `/jdrs/${this.game.id}/characters/${character.playerId}`), character);
                }
            }
            await set(ref(db, `/jdrs/${this.game.id}/groups`), groups);
            this.modalDeleteGroup = false;
        }
    }

}
