
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Game, JDR} from '@/types/Interfaces';
import {Spell, spells} from "@/components/LandOfTheDread/spells";
import {IRoll} from "@/types/Roll";
import {get, ref, set} from "firebase/database";
import {db} from "@/firebase";
import { NormalRoll } from '@/types/NormalRoll';

@Component
export default class LotdSpellsComponent extends Vue {
    @Prop() private game !: Game;
    @Prop() private id !: number;

    spells: Spell[] = [];

    infos = -1;

    mounted(): void {
        this.setupSpells();
    }

    classe = this.game.characters[this.id].informations.filter(i => i.name == 'classe')[0].value;
    sousClasse = this.game.characters[this.id].informations.filter(i => i.name == 'sous-classe')[0].value;
    niveau = this.game.characters[this.id].informations.filter(i => i.name == 'niveau')[0].value as number;
    setupSpells(): void {
        this.spells = spells;
    }

    async doSpell(spell: Spell) {
        this.game.characters[this.id].stats.filter(s => s.name == 'vigueur')[0].current -= 1
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}/stats`), this.game.characters[this.id].stats);
        const roll = new NormalRoll(false, JDR.LandOfTheDread, this.game.characters[this.id].name)
        if (spell.roll != "") {
            roll.roll(spell.roll)
            roll.text += ` ${spell.name}`
        } else {
            roll.text = spell.name
        }
        roll.CSSClass = "succes"
        this.addGlobalRoll(roll)
        this.addRoll(roll)
    }

    async addRoll(roll: IRoll): Promise<void> {
        if (!this.game.characters[this.id].rolls) {
            this.game.characters[this.id].rolls = [];
        }
        this.game.characters[this.id].rolls = [roll, ...this.game.characters[this.id].rolls];
        if (this.game.characters[this.id].rolls.length > 20) this.game.characters[this.id].rolls.pop();
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}/rolls`), this.game.characters[this.id].rolls);
    }

    async addGlobalRoll(roll: IRoll): Promise<void> {
        let globalRolls: IRoll[] | null = null;
        await get(ref(db, `/jdrs/${this.game.id}/rolls`)).then(snap => {
            globalRolls = snap.val();
        });
        if (globalRolls === null) globalRolls = [];
        globalRolls = [roll, ...globalRolls];
        globalRolls = globalRolls.slice(0, 100);
        await set(ref(db, `/jdrs/${this.game.id}/rolls`), globalRolls);
    }
}
