
import JdrComponent from '../components/JdrComponent.vue';
import { db } from '@/firebase';
import { get, ref } from 'firebase/database';
import { Vue, Component } from 'vue-property-decorator';

@Component({
    components: {
        JdrComponent,
    },
})

export default class Jdr extends Vue {

    jdrList: string[] = [];

    async created(): Promise<void> {
        try {
            const jdrsRef = ref(db, '/content');
            await get(jdrsRef).then((snapshot) => {
                Object.keys(snapshot.val()).forEach(value => {
                    this.jdrList.push(value);
                });
            });
        } catch (error) {
            console.log('error : ' + error);
        }
    }
}
