import { Roll } from '@/types/Roll';
import { JDR } from '@/types/Interfaces';
import { ref, set } from 'firebase/database';
import { db } from '@/firebase';
import { Character } from '@/types/Interfaces';

export class PadoRoll extends Roll {

    constructor(stat: string, playerName: string) {
        super(stat, JDR.ParceDomine, playerName);
    }

    async roll(character: Character, secret: boolean): Promise<void> {
        this.statValue = character.characteristics.filter(c => c.name === this.stat)[0].value;
        const vigueur = character.stats.filter(s => s.name === 'vigueur')[0].current;
        const mental = character.stats.filter(s => s.name === 'mental')[0].current;
        const rand: number = Math.floor(Math.random() * 20) + 1; //picks a random number between 1 and 20
        // In ParceDomine, there are 2 types of rolls :
        // "agilite", "force", "rapidite" and "parade" which use "vigueur"
        // and "volonte" and "intuition" which use "mental"
        if (vigueur != undefined && (this.stat == 'agilite'
            || this.stat == 'force'
            || this.stat == 'rapidite'
            || this.stat == 'parade')) {
            if (vigueur < 0) {
                const reduce = Math.max(-15, vigueur as number * 3);
                this.statValue = Math.max(1, this.statValue + reduce);
            }
            this.rolled = rand;
            const vigueurRef = ref(db, `/jdrs/${character.gameId}/characters/${character.playerId}/stats/2/current`);
            await (set(vigueurRef, vigueur as number - 1));
        } else if (mental != undefined && (this.stat == 'volonte' || this.stat == 'intuition')) {
            if (mental < 0) {
                const reduce = Math.max(-15, mental as number * 3);
                this.statValue = Math.max(1, this.statValue + reduce) as number;
            }
            this.rolled = rand;
            const jdrsRef = ref(db, `/jdrs/${character.gameId}/characters/${character.playerId}/stats/3/current`);
            await set(jdrsRef, mental as number - 1);
        } else {
            console.log('erreur');
        }
        this.secret = secret;
        //Modifies passed and critic fields according to roll
        this.passed = (this.rolled && this.rolled <= this.statValue) as boolean;
        this.critic = (this.rolled && (this.rolled == 20 || this.rolled == 1)) as boolean;
        this.setText();
        this.setCSSClass();
    }
}