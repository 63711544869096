
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Game} from '@/types/Interfaces';

@Component
export default class PadoPlayerTurnOrderComponent extends Vue {
    @Prop() private game !: Game;

    async joinTurn(): Promise<void> {
        await this.game.turnOrderaddCharacter(this.$store.state.user.uid, false);
    }

    async joinTurnSecret(): Promise<void> {
        await this.game.turnOrderaddSecretCharacter(this.$store.state.user.uid);
    }

    async nextTurn(): Promise<void> {
        await this.game.turnOrderNext();
    }

    async fuite(): Promise<void> {
        await this.game.turnOrderRemoveCharacter(this.$store.state.user.uid);
    }
}
