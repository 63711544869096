import Vue from "vue";
import Vuex from "vuex";
import {User} from "firebase/auth";

Vue.use(Vuex);

interface IState {
  user: User | undefined;
}

export default new Vuex.Store({
  state: {
    user: undefined,
  } as IState,
  mutations: {
    setUser(state, user: User) {
      state.user = user;
    },
  },
  actions: {},
  modules: {},
});
