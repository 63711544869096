import {Element} from './ethnies';

export class Spell {
    constructor(public name: string,
                public description: string,
                public level: number,
                public category: string,
                public element: Element,
                public damage: string){
    }

    isMagic(): boolean {
        return (this.category === 'magie élémentaire'
            || this.category === 'sagesse'
            || this.category === 'ambition'
            || this.category === 'bravoure'
            || this.category === 'piété'
            || this.category === 'justice')
    }
}

export const spells: Spell[] = [

    new Spell(
        "Télépathie de courte distance",
        "Permet de communiquer télépathiquement avec un PJ / PnJ dans le même périmètre.",
        0,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Globe luminescent",
        "Créé un halo de lumière au-dessus de la paume de la main.",
        0,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Télépathie de moyenne distance",
        "Permet de communiquer télépathiquement avec un PJ / PnJ à une distance médiante.",
        1,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Désarmement",
        "Expulse l'arme de l'adversaire au loin, l'empêchant de lancer des sorts ou d'attaquer pendant le prochain tour, et jusqu'à ce qu'il la récupère.",
        1,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Rempart télépathique",
        "Créé une barrière mentale qui protège le lanceur des intrusions télépathiques d'autres mages. Permet en outre de rendre son expression indéchiffrable durant une conversation.",
        1,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Télépathie de longue-distance",
        "Permet de communiquer télépathiquement avec un ou plusieurs PJ / PnJ à une longue distance.",
        2,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Double utilisation d'armes",
        "Lorsque le lanceur de sorts possède une deuxième arme, permet de doubler les effets du prochain sort.",
        2,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Déviation de fluxs magiques",
        "Dévie la trajectoire de l'attaque adverse.",
        2,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Détection de mensonge",
        "Lancé au cours d'une conversation, permet de déterminer si un PJ / PnJ ment ouvertement.",
        2,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Éclat télépathique",
        "Cette attaque télépathique permet de désarçonner l'adversaire et de lui asséner des dommages imparables.",
        2,
        "sagesse",
        Element.null,
        "1d10 + 10"
    ),
    new Spell(
        "Brise-lame",
        "Permet au lanceur de briser l'arme de son adversaire.",
        3,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Annulation de fluxs magiques",
        "Annihile l'attaque de l'adversaire.",
        3,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Possession télépathique",
        "Permet de prendre possession de la cible et de ses capacités magiques et physiques pendant 1d3 tour(s). Le lanceur est pendant ce laps de temps inconscient et ne peut plus participer au combat. Son corps est vulnérable et incapable de répondre aux attaques de l'extérieur.",
        3,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Projection astrale",
        "Créé un double télépathique (distance variable)",
        3,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Memorandum",
        "Permet de plonger dans la mémoire d'un objet et de remonter dans son histoire.",
        3,
        "sagesse",
        Element.null,
        ""
    ),
    new Spell(
        "Harcèlement",
        "Lance une attaque magique sur l'adversaire. Lancer 1d3 : selon le résultat, envoie une salve du nombre indiqué d’attaques. La cible doit les parer une par une.",
        0,
        "ambition",
        Element.null,
        "10"
    ),
    new Spell(
        "Empoisonnement",
        "Applique un poison à la cible, qui lui cause des dégâts pendant les 3 prochains tours.",
        0,
        "ambition",
        Element.null,
        ""
    ),
    new Spell(
        "Toxine tenace",
        "Applique un poison à la cible, qui lui cause des dégâts pendant les 6 prochains tours.",
        1,
        "ambition",
        Element.null,
        ""
    ),
    new Spell(
        "Manteau des ombres",
        "Permet au lanceur de se rendre invisible de façon temporaire, pendant 1d2 tour(s).",
        1,
        "ambition",
        Element.null,
        ""
    ),
    new Spell(
        "Riposte de la lame cachée",
        "Lorsqu'un adversaire lance une attaque sur vous, permet de parer le coup et lui infliger un dé de dégâts.",
        1,
        "ambition",
        Element.null,
        "1d10 + 10"
    ),
    new Spell(
        "Trait d'annihilation",
        "Permet de créer une puissante attaque qui inflige des dégâts en ligne. Consomme l'état \"Empoisonné\".",
        2,
        "ambition",
        Element.null,
        "1d10 + 35"
    ),
    new Spell(
        "Anti-guérison",
        "Empêche la cible de se soigner pendant 1d5 tours. Convertit les soins en dégâts.",
        2,
        "ambition",
        Element.null,
        ""
    ),
    new Spell(
        "Vampirisme magique",
        "Absorbe l'attaque magique adverse et la convertit en bonus d'1d2 de Vigueur. Ne fonctionne pas sur les attaques majeures.",
        2,
        "ambition",
        Element.null,
        ""
    ),
    new Spell(
        "Arcades de téléportation",
        "Créé deux arcades de téléportation sur la map qui correspondent l'une avec l'autre. Maximum autorisé : 6.",
        2,
        "ambition",
        Element.null,
        ""
    ),
    new Spell(
        "Miroir d'Ibad",
        "Renvoie l'attaque lancée par l'adversaire.",
        2,
        "ambition",
        Element.null,
        ""
    ),
    new Spell(
        "Synergie de la toxine",
        "Tous les dégâts subis par un ennemi dans l'état \"empoisonné\" sont transmis aux autres ennemis dans le même état.",
        3,
        "ambition",
        Element.null,
        ""
    ),
    new Spell(
        "Transmutation magique",
        "Asorbe l'attaque magique adverse et la convertit en contre-attaque. Cumulable : plus vous absorbez d'attaques, plus votre contre-attaque sera puissante et létale. Maximum autorisé : 4",
        3,
        "ambition",
        Element.null,
        ""
    ),
    new Spell(
        "Maëlstrom",
        "Les ennemis dans la zone de radius du Maëlstrom perdent tous les bonus de compétences octroyés par leurs équipements (protections, armes, etc). Le malus est perdu lorsqu'ils sortent de la zone du Maëlstrom.",
        3,
        "ambition",
        Element.null,
        ""
    ),
    new Spell(
        "Corruption des lèvres parjurées",
        "Lancé sur une cible, la soumet à un puissant poison qui peut, à terme l'éliminer. Au bout d'1d3 de mensonges proférées par la cible, celle-ci meurt subitement.",
        3,
        "ambition",
        Element.null,
        ""
    ),
    new Spell(
        "Metanoia",
        "Créé 1d15 de doubles magiques sur la map pendant 1d3 tour(s). Le lanceur devient imperceptible et peut lancer ses attaques depuis la position d'un de ses doubles encore debout.",
        3,
        "ambition",
        Element.null,
        ""
    ),
    new Spell(
        "Impact télékinétique",
        "Permet de frapper un opposant sans ligne de vue, à distance.",
        0,
        "bravoure",
        Element.null,
        "15"
    ),
    new Spell(
        "Éloignement",
        "Éloigne l'objet / l'entité du lanceur.",
        0,
        "bravoure",
        Element.null,
        ""
    ),
    new Spell(
        "Bouclier défensif",
        "Créé une sphère magique qui pare automatiquement 1d2 attaque(s).",
        1,
        "bravoure",
        Element.null,
        ""
    ),
    new Spell(
        "Fléchissement terrestre",
        "Frappe le sol et désarçonne tous les ennemis du radius de combat. Lancer 1d3. Si 1 = les ennemis perdent 4 points dans leur compétences physiques pendant le prochain tour et subissent des dégâts mineurs. Si 2 = les ennemis perdent 4 points dans leur compétences physiques. Si 3 = les ennemis perdent 2 points dans leur compétences physiques.",
        1,
        "bravoure",
        Element.null,
        "10"
    ),
    new Spell(
        "Attraction",
        "Rapproche l'objet / l'entité du lanceur.",
        1,
        "bravoure",
        Element.null,
        ""
    ),
    new Spell(
        "Bouclier offensif",
        "Créé une sphère magique qui implose et projette les adversaires au CàC au sol, leur faisant subir des dégâts à deux reprises (lors de l'impact et lors de leur chute).",
        2,
        "bravoure",
        Element.null,
        "1d10 + 10"
    ),
    new Spell(
        "Parure révérée",
        "Le lanceur se couvre quasiment intégralement d'une armure adamantine aux aspérités tranchantes. Réduit pendant 1d3 tour(s) les dégâts physiques reçus. Les dégâts physiques commis sont ceux d'une arme blanche, au même titre qu'une longue épée.",
        2,
        "bravoure",
        Element.null,
        ""
    ),
    new Spell(
        "Tourbillon cinglant",
        "Lancer un tourbillon magique sur une cible unique (une utilisation par tour).",
        2,
        "bravoure",
        Element.null,
        "1d10 + 25"
    ),
    new Spell(
        "Manipulation télékinétique",
        "Permet de manipuler à volonté l'objet / l'entité ciblé(e) par le lanceur.",
        2,
        "bravoure",
        Element.null,
        ""
    ),
    new Spell(
        "Marche sur l'eau",
        "Permet au lanceur de marcher sur l'eau.",
        2,
        "bravoure",
        Element.null,
        ""
    ),
    new Spell(
        "Bastion magique",
        "Place dans le radius de combat un grand rempart magique qui pare les attaques de type basique à médian pendant 3 tours. Les attaques majeures réduisent la durée de vie du bastion d'un tour.",
        3,
        "bravoure",
        Element.null,
        ""
    ),
    new Spell(
        "Métempsycose télékinétique",
        "Le mage lance 1d3. Si 1 = détruit l'objet / l'entité ciblé(e), à la condition que l'objet ne soit pas un artéfact unique et que la cible ne soit pas au-dessus du niveau 15. Si 2 = Le brise en deux / Mutile la cible, à la condition que l'objet ne soit pas un artéfact unique et que la cible ne soit pas au-dessus du niveau 15. Si 3 = l'endommage.",
        3,
        "bravoure",
        Element.null,
        "1d3"
    ),
    new Spell(
        "Respiration aquatique",
        "Permet au lanceur de respirer sous l'eau.",
        3,
        "bravoure",
        Element.null,
        ""
    ),
    new Spell(
        "Vol",
        "Le lanceur lance la télékinésie sur son propre corps et peut maintenant léviter.",
        3,
        "bravoure",
        Element.null,
        ""
    ),
    new Spell(
        "Disque acéré",
        "Lance un disque de magie sur l'adversaire. Sa tranche est fine comme une lame aiguisée et peut déchirer la matière brute comme organique.",
        3,
        "bravoure",
        Element.null,
        "1d10 + 45"
    ),
    new Spell(
        "Soin basique",
        "Soigne la cible de 5 PV.",
        0,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Redistribution basique",
        "Booste la cible de +1 point de compétence dans ses compétences de prédilection (physiques ou magiques) pendant 1 tour. Les classes hybrides choisissent le domaine de compétences à booster.",
        0,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Soin mineur",
        "Soigne la cible de 10 PV.",
        1,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Mur venteux",
        "Repousse la cible, qu'elle soit au CàC ou à distance. Si la cible percute une surface, elle subit des dégâts mineurs.",
        1,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Pacification",
        "Calme l'instinct de prédation de l'animal ciblé.",
        1,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Redistribution médiane",
        "Booste la cible de +2 points de compétence dans ses compétences de prédilection (physiques ou magiques) pendant 1 tour. Les classes hybrides choisissent le domaine de compétences à booster.",
        2,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Sacralisation",
        "Booste les dégâts de la cible de 50% sur ses 1d3 prochains jets.",
        2,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Régénération",
        "Régénère 50 + 1d30 PV de la cible, mais baisse ses PV totaux du résultat du 1d30.",
        2,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Symbiose",
        "Fait obéir une créature aux commandements du lanceur. En combat : prend possession du tour d'une créature adverse.",
        2,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Lys de feu",
        "Place sur le terrain un lys de feu dont le bulbe souterrain va se gorger de tout jet magique effectué à la surface. Plus la quantité est grande, plus la redistribution (en zone autour du lys) l'est aussi. 1 point Vigueur par action magique (plafond à 6). Chaque cible à proximité de l'éclosion du lys empoche le nombre de points accumulés par le bulbe.",
        2,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Soin majeur",
        "Soigne la cible de 30 + 1d10 PV.",
        3,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Redistribution majeure",
        "Booste la cible de +3 points de compétence dans ses compétences de prédilection (physiques ou magiques) pendant 1 tour. Les classes hybrides choisissent le domaine de compétences à booster.",
        3,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Pourfendeur de ténèbres",
        "Créé un glaive et un bouclier de lumière qui n'interagissent qu'avec des adversaires empreints de malice. Les dés de Piété servent désormais de jets d'attaque et de parade. Plus les adversaires sont intrinsèquement liés aux ténèbres, plus l'efficacité du 'Pourfendeur' est grande.",
        3,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Télépathie animale",
        "Permet de communiquer avec l'animal ciblé.",
        3,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Coeur charivarique",
        "Le lanceur créé une gangue de magie qui explose et ébranle les sens des adversaires qui ne peuvent pas jouer pendant leur prochain tour. Le lanceur peut en outre prendre la fuite s'il le souhaite, sans craindre une attaque d'opportunité. Le tour suivant, ceux-ci sont pénalisés de 3 points de compétences sur tous les jets de dés, puis 2, puis 1.",
        3,
        "piété",
        Element.null,
        ""
    ),
    new Spell(
        "Entravement basique",
        "Pénalise la cible de -1 point de compétence dans les compétences de prédilection (physiques ou magiques) de la cible pendant 1 tour. Les classes hybrides sont pénalisées dans l'un de leurs deux domaines de compétences au choix.",
        0,
        "justice",
        Element.null,
        ""
    ),
    new Spell(
        "Boulet magique",
        "Envoie un projectile magique sur la cible. Augmente de 5 les dégâts du sort pour le prochain tour (non-cumulable).",
        0,
        "justice",
        Element.null,
        "15"
    ),
    new Spell(
        "Entravement mineur",
        "Pénalise la cible de -1 point de compétence dans les compétences de prédilection (physiques ou magiques) de la cible pendant 2 tours. Les classes hybrides sont pénalisées dans l'un de leurs deux domaines de compétences au choix.",
        1,
        "justice",
        Element.null,
        ""
    ),
    new Spell(
        "Évanescence",
        "Vampirise les PV d'un adversaire au CàC.",
        1,
        "justice",
        Element.null,
        "1d10 + 10"
    ),
    new Spell(
        "Schisme",
        "Créé une bourrasque qui sépare deux personnes au CàC, les éloignant l'une de l'autre. Sort sans ligne de mire.",
        1,
        "justice",
        Element.null,
        ""
    ),
    new Spell(
        "Entravement médian",
        "Pénalise la cible de -2 points de compétence dans les compétences de prédilection (physiques ou magiques) de la cible pendant 2 tours. Les classes hybrides sont pénalisées dans l'un de leurs deux domaines de compétences au choix.",
        2,
        "justice",
        Element.null,
        ""
    ),
    new Spell(
        "Éminence magique",
        "Créé une entité angélique au-dessus du radius de combat. Toute personne qui cherche à fuir le radius de combat perd 15 PV, qu'elle réussisse ou qu'elle échoue. L'ange reste en vol durant 4 tours.",
        2,
        "justice",
        Element.null,
        ""
    ),
    new Spell(
        "Microcosme",
        "Créé une cage de magie. En combat, isole cette zone pendant 2 tours.",
        2,
        "justice",
        Element.null,
        ""
    ),
    new Spell(
        "Retour de flamme",
        "La cible renvoie la moitié des dommages reçus pendant 2 tours.",
        2,
        "justice",
        Element.null,
        ""
    ),
    new Spell(
        "Abrogation",
        "Enlève tous les effets, passifs comme actifs, positifs comme négatifs, de la cible.",
        2,
        "justice",
        Element.null,
        ""
    ),
    new Spell(
        "Entravement majeur",
        "Pénalise la cible de -3 points de compétence dans les compétences de prédilection (physiques ou magiques) de la cible pendant 2 tours. Les classes hybrides sont pénalisées dans l'un de leurs deux domaines de compétences au choix.",
        3,
        "justice",
        Element.null,
        ""
    ),
    new Spell(
        "Châtiment stellaire",
        "Convoque une puissante masse de magie qui s'abat sur la cible.",
        3,
        "justice",
        Element.null,
        "1d10 + 55"
    ),
    new Spell(
        "Enfermement",
        "Créé une cage de magie individuelle qui enferme la cible. En combat, isole le PJ/PnJ pendant 2 tours.",
        3,
        "justice",
        Element.null,
        ""
    ),
    new Spell(
        "Grandes chaînes de damnation",
        "Occasione des dommages à la cible. Consomme tous les effets d'entravement lancés au préalable sur la cible. Par effet = 10 PV en moins. ",
        3,
        "justice",
        Element.null,
        "40"
    ),
    new Spell(
        "Obstruction",
        "Réduit au stade basique la portée des sorts et capacités de longue-distance de la cible.",
        3,
        "justice",
        Element.null,
        ""
    ),
    new Spell(
        "Attaque basique",
        "Inflige des dégâts basiques à l'adversaire.",
        0,
        "force brute",
        Element.null,
        "10"
    ),
    new Spell(
        "Exaltation basique",
        "Octroie 5 dommages supplémentaires sur la prochaine attaque.",
        0,
        "force brute",
        Element.null,
        ""
    ),
    new Spell(
        "Attaque mineure",
        "Inflige des dégâts mineurs à l'adversaire.",
        1,
        "force brute",
        Element.null,
        "15"
    ),
    new Spell(
        "Exaltation mineure",
        "Octroie 10 dommages supplémentaires sur la prochaine attaque.",
        1,
        "force brute",
        Element.null,
        ""
    ),
    new Spell(
        "Bond",
        "Projette le lanceur sur l'endroit visé. Occasionne des dégâts basiques en zone, autour du lanceur. Ne peut être lancé que tous les 3 tours.",
        1,
        "force brute",
        Element.null,
        "1d10 + 5"
    ),
    new Spell(
        "Attaque médiante",
        "Inflige des dégâts médians à l'adversaire.",
        2,
        "force brute",
        Element.null,
        "20"
    ),
    new Spell(
        "Exaltation médiante",
        "Octroie 15 dommages supplémentaires sur la prochaine attaque.",
        2,
        "force brute",
        Element.null,
        ""
    ),
    new Spell(
        "Marteau ascendant",
        "Le lanceur prend de la hauteur et augmente la puissance de son coup. L'adversaire, a contrario, perd en efficacité pour parer ou esquiver le coup. Réduit de 2 points de compétences le jet du PJ et augmente de 2 celui de l'adversaire.",
        2,
        "force brute",
        Element.null,
        "1d15 + 30"
    ),
    new Spell(
        "Anneau de lacération",
        "Inflige des dommages en cercle autour du lanceur.",
        2,
        "force brute",
        Element.null,
        "1d10 + 10"
    ),
    new Spell(
        "Étau",
        "Lancé sur une cible au CàC, la soumet à un face-à-face continuel avec le PJ. Chaque tentative de s'enfuir (probante ou non) ou de lancer une attaque sur un autre personnage que le PJ lui fait subir une attaque d'opportunité (10 PV en moins). ",
        2,
        "force brute",
        Element.null,
        ""
    ),
    new Spell(
        "Attaque majeure",
        "Inflige des dégâts majeurs à l'adversaire.",
        3,
        "force brute",
        Element.null,
        "25"
    ),
    new Spell(
        "Témérité retrouvée",
        "Permet pendant 1d3 tours de repousser le plafond de la compétence \"Force Brute\" à 18. Lorsque le nombre de tours est épuisé, le PJ perd temporairement 1 point de compétence pendant 1 tour.",
        3,
        "force brute",
        Element.null,
        ""
    ),
    new Spell(
        "Assaut emporté",
        "Attaque en zone qui peut être lancée au CàC comme à distance. Inflige des dégâts jusqu'à trois ennemis proches les uns des autres. Ne peut être lancé qu'une fois tous les 2 tours.",
        3,
        "force brute",
        Element.null,
        "1d15 + 25"
    ),
    new Spell(
        "Ire de Jor Orson",
        "Inflige des dégâts conséquents à une cible unique, au corps-à-corps. Ne peut être lancé que tous les 4 tours.",
        3,
        "force brute",
        Element.null,
        "1d15 + 60"
    ),
    new Spell(
        "Furie",
        "Le lanceur dépense 2 points de Vigueur, mais fait subir le double de dégâts à son adversaire sur sa prochaine attaque.",
        3,
        "force brute",
        Element.null,
        ""
    ),
    new Spell(
        "Coup sournois basique",
        "Lancé par surprise, permet au PJ d'infliger des dégâts basiques à l'adversaire.",
        0,
        "dextérité",
        Element.null,
        "15"
    ),
    new Spell(
        "Esquive basique",
        "Le PJ pivote et esquive l'attaque de l'aversaire. Le résultat du jet adverse est augmenté de 1 point de compétence.",
        0,
        "dextérité",
        Element.null,
        ""
    ),
    new Spell(
        "Coup sournois mineur",
        "Lancé par surprise, permet au PJ d'infliger des dégâts mineurs à l'adversaire.",
        1,
        "dextérité",
        Element.null,
        "20"
    ),
    new Spell(
        "Esquive mineure",
        "Le PJ pivote et esquive l'attaque de l'adversaire. Le résultat du jet adverse est augmenté de 2 points de compétence.",
        1,
        "dextérité",
        Element.null,
        ""
    ),
    new Spell(
        "Sens alertes",
        "Permet de détecter une présence intruse dans un radius de 10 mètres. En combat, dévoile un adversaire dissimulé, si celui-ci se trouve dans le radius de la capacité.",
        1,
        "dextérité",
        Element.null,
        ""
    ),
    new Spell(
        "Coup sournois médian",
        "Lancé par surprise, permet au PJ d'infliger des dégâts médians à l'adversaire.",
        2,
        "dextérité",
        Element.null,
        "30"
    ),
    new Spell(
        "Esquive médiante",
        "Le PJ pivote et esquive l'attaque de l'aversaire. Le résultat du jet adverse est augmenté de 3 points de compétence.",
        2,
        "dextérité",
        Element.null,
        ""
    ),
    new Spell(
        "Tour de passe-passe",
        "Le lanceur jongle avec sa lame et la passe d'une main à l'autre, rendant son attaque plus imprévisible. Si le prochain jet d'attaque du lanceur échoue, permet de relancer le dé, et de rendre les dégâts critiques.",
        2,
        "dextérité",
        Element.null,
        ""
    ),
    new Spell(
        "Feinte",
        "Force l'adversaire à relancer son dé d'attaque si celui-ci est réussi. Un nouveau point de vigueur doit être dépensé par celui-ci.",
        2,
        "dextérité",
        Element.null,
        ""
    ),
    new Spell(
        "Lucarne",
        "Permet au lanceur de frapper une cible à distance, et sans ligne de mire. Cette technique ne peut être utilisée que tous les 2 tours.",
        2,
        "dextérité",
        Element.null,
        ""
    ),
    new Spell(
        "Coup sournois majeur",
        "Lancé par surprise, permet au PJ d'infliger des dégâts majeurs à l'adversaire.",
        3,
        "dextérité",
        Element.null,
        "40"
    ),
    new Spell(
        "Esquive majeure",
        "Le PJ pivote et esquive l'attaque de l'aversaire. Le résultat du jet adverse est augmenté de 4 points de compétence. Le jet le plus bas l'emporte.",
        3,
        "dextérité",
        Element.null,
        ""
    ),
    new Spell(
        "Angle mort",
        "Le lanceur détecte une faille dans la défense de son adversaire. Les dégâts infligés par sa prochaine attaque sont doublés.",
        3,
        "dextérité",
        Element.null,
        ""
    ),
    new Spell(
        "Dard dissimulé",
        "Si l'adversaire réussit à bloquer ou esquiver une attaque du lanceur, permet de renchérir sur une attaque bonus, permise grâce à une lame aiguisée dissimulée dans l'armure du PJ. Cette attaque ne peut pas être parée.",
        3,
        "dextérité",
        Element.null,
        "1d10 + 30"
    ),
    new Spell(
        "Cuirasse épineuse",
        "Chaque attaque subie au CàC par le PJ blesse ses adversaires, qui perdent de la vie à mesure qu'ils l'attaquent. La cuirasse se brise après un total de 5 assauts. Les dommages octroyés augmentent de 5 après chaque attaque. Dégâts de base = 10.",
        3,
        "dextérité",
        Element.null,
        ""
    ),
    new Spell(
        "Parade basique",
        "Pare l'attaque adverse. Si le jet échoue, réduit les dommages reçus par 5.",
        0,
        "résistance",
        Element.null,
        ""
    ),
    new Spell(
        "Pivot",
        "Le PJ pare l'arme de son adversaire, se dérobe, et échange de position avec lui.",
        0,
        "résistance",
        Element.null,
        ""
    ),
    new Spell(
        "Parade mineure",
        "Pare l'attaque adverse. Si le jet échoue, réduit les dommages reçus par 10.",
        1,
        "résistance",
        Element.null,
        ""
    ),
    new Spell(
        "Attaque de barrage",
        "Fait subir des dégâts mineurs à l'adversaire et le désarme. Celui-ci ne peut plus attaquer pendant son prochain tour.",
        1,
        "résistance",
        Element.null,
        "1d10 + 10"
    ),
    new Spell(
        "Enracinement",
        "Le lanceur ne peut être déplacé par une technique adverse pendant les 2 prochains tours. Ne peut être lancé que tous les 4 tours.",
        1,
        "résistance",
        Element.null,
        ""
    ),
    new Spell(
        "Parade médiante",
        "Pare l'attaque adverse. Si le jet échoue, réduit les dommages reçus par 15.",
        2,
        "résistance",
        Element.null,
        ""
    ),
    new Spell(
        "Forteresse imprenable",
        "Le lanceur se dote d'une volonté d'acier et résiste aux assauts des attaques avderses, aussi puissantes soient-elles. Les dégâts reçus sont divisés par 2 pendant les 3 prochains tours, mais les dommages infligés aux adversaires sont divisés par 2 pendant les 3 prochains tours. Le lanceur est pendant ce laps de temps indéplaçable, et ne peut pas se mouvoir sur le terrain.",
        2,
        "résistance",
        Element.null,
        ""
    ),
    new Spell(
        "Révocation",
        "Repousse les adversaires au CàC de plusieurs mètres en leur occasionant des dommages légers.",
        2,
        "résistance",
        Element.null,
        "10"
    ),
    new Spell(
        "Ange gardien",
        "Le PJ choisit un PJ/PnJ proche de lui et se tient entre lui et ses ennemis. Iel devient son adjuvant et répond aux attaques adverses à sa place pendant les 2 prochains tours.",
        2,
        "résistance",
        Element.null,
        ""
    ),
    new Spell(
        "Stèle protectrice",
        "Le PJ plante son bouclier dans le sol à un endroit stratégique de la zone de combat. Le bouclier bloque la ligne de vue des adversaires et reste planté jusqu'à ce que le PJ décide de le retirer. Sans son bouclier, le PJ perd cependant 2 points lorsqu'il souhaite parer une attaque avderse. Le bouclier peut être repris en main au prochain tour du joueur.",
        2,
        "résistance",
        Element.null,
        ""
    ),
    new Spell(
        "Parade majeure",
        "Pare l'attaque adverse. Si le jet échoue, réduit les dommages reçus par 20.",
        3,
        "résistance",
        Element.null,
        ""
    ),
    new Spell(
        "Barrage robuste",
        "Divise les dégâts reçus par 2 pendant 1d3 tours. Les attaques parées pendant ce laps de temps sont repoussées violemment et envoient les adversaires à terre, leur octroyant des dégâts basiques.",
        3,
        "résistance",
        Element.null,
        ""
    ),
    new Spell(
        "Joute létale",
        "Aux prises avec l'adversaire, le lanceur pare son attaque et l'envoie violemment au tapis. Lancer 1d3. Si 1 = l'adversaire est étourdi par la joute et doit passer son prochain tour. Si 2 = iel est pénalisé.e de 3 points de compétences sur son prochain jet. Si 3 = l'adversaire joue son prochain tour normalement. Ne peut être lancé que tous les 3 tours.",
        3,
        "résistance",
        Element.null,
        "1d10 + 25"
    ),
    new Spell(
        "Éternité",
        "Lancé par le PJ, ce sort passif lui permet de croître en puissance à mesure que le combat se prolonge. Tous les 3 tours, le PJ gagne 10 dommages en plus (cumulables), ainsi qu'un point de compétence supplémentaire sur ses jets d'attaque. Ne peut être lancé qu'une fois par combat.",
        3,
        "résistance",
        Element.null,
        ""
    ),
    new Spell(
        "Second souffle",
        "Si la barre de vie du lanceur passe en-dessous des 50 PV, créé immédiatement un bouclier du même nombre de PV en supplément qui lui permet de ralentir la décélération de sa barre de vie. Ne peut être lancé qu'une fois par combat.",
        3,
        "résistance",
        Element.null,
        ""
    ),
    new Spell(
        "Coup d'estoc basique",
        "Lancer 1d4 : Si 1 = le lanceur désarçonne la parade (réussie ou non) de son adversaire et peut renchérir sur une attaque d'opportunité, imparable et inesquivable par l’adversaire. Si 2, 3 ou 4 et que l'adversaire échoue à parer ou esquiver, lui occasionne des dégâts basiques.",
        0,
        "célérité",
        Element.null,
        "10"
    ),
    new Spell(
        "Fuite",
        "Permet au PJ de fuir la zone de combat. Si le jet échoue, le lanceur subit une attaque d'opportunité.",
        0,
        "célérité",
        Element.null,
        ""
    ),
    new Spell(
        "Coup d'estoc mineur",
        "Lancer 1d3 : Si 1 = le lanceur désarçonne la parade (réussie ou non) de son adversaire et peut renchérir sur une attaque d'opportunité, imparable et inesquivable par l’adversaire. Si 2 ou 3, et que l'adversaire échoue à parer ou esquiver, lui occasionne des dégâts mineurs.",
        1,
        "célérité",
        Element.null,
        "15"
    ),
    new Spell(
        "Attaque en piqué",
        "Le PJ, s'il se trouve en hauteur, lance une attaque sur sa cible et lui occasionne des dégâts qui augmentent après chaque utilisation : +5 dégâts / utilisation. Attention : les dégâts sont cumulables uniquement lorsque la suite de jets est réussie et ininterrompue.",
        1,
        "célérité",
        Element.null,
        "1d10 + 5"
    ),
    new Spell(
        "Magnitude",
        "Les trois prochaines attaques du PJ occasionneront des dégâts critiques aux ennemis.",
        1,
        "célérité",
        Element.null,
        ""
    ),
    new Spell(
        "Coup d'estoc médian",
        "Lancer 1d2 : Si 1 = le lanceur désarçonne la parade (réussie ou non) de son adversaire et peut renchérir sur une attaque d'opportunité, imparable et inesquivable par l’adversaire. Si 2, et que l'adversaire échoue à parer ou esquiver, lui occasionne des dégâts mineurs.",
        2,
        "célérité",
        Element.null,
        "20"
    ),
    new Spell(
        "Percée du fer-de-lance",
        "Occasione des dommages en ligne et déplace le lanceur de l'autre côté du dernier ennemi visé par le rayon de l'attaque.",
        2,
        "célérité",
        Element.null,
        "1d10 + 15"
    ),
    new Spell(
        "Escapade",
        "En s'appuyant sur un adversaire au CàC, le lanceur s'extrait du CàC en occasionnant de légers dégâts.",
        2,
        "célérité",
        Element.null,
        "10"
    ),
    new Spell(
        "Revers de lame",
        "En utilisant le revers de sa lame, le lanceur renvoie instantanément les dégâts d'une attaque portée sur iel. Ne peut être lancé que tous les 3 tours.",
        2,
        "célérité",
        Element.null,
        ""
    ),
    new Spell(
        "Frappe de criblage",
        "Occasione des dégâts médians à l'adversaire en pénalisant sa liberté de mouvement. La cible de l'attaque ne peut pas se mouvoir sur le terrain pendant 1d3 tours.",
        2,
        "célérité",
        Element.null,
        "1d10 + 15"
    ),
    new Spell(
        "Coup d'estoc majeur",
        "Occasione des dégâts majeurs à l'adversaire et enchaîne sur une attaque d'opportunité. Si l'adversaire réussit à parer le coup, il subit simplement des dégâts d'opportunité.",
        3,
        "célérité",
        Element.null,
        "25"
    ),
    new Spell(
        "Lame insaisissable",
        "Occasionne des dommages sévères à l'adversaire qui peine à suivre les mouvements décrits par la lame. L'adversaire a une pénalité de 3 points sur son jet d'esquive ou de parade. Ne peut être lancé que tous les deux tours.",
        3,
        "célérité",
        Element.null,
        "1d10 + 35"
    ),
    new Spell(
        "Vélocité",
        "Lancé au début du tour du PJ, permet d'attaquer deux fois pendant son tour. Ne peut être lancé que tous les 4 tours.",
        3,
        "célérité",
        Element.null,
        ""
    ),
    new Spell(
        "Chemin sinueux",
        "Créé un chemin de passage au milieu d'une mêlée dans lequel transvase le PJ. Sur ce chemin, iel peut harceler plusieurs adversaires à la fois (maximum : 4) d'attaques d'opportunité ne pouvant être parées. Le PJ est téléporté de l'autre côté du chemin.",
        3,
        "célérité",
        Element.null,
        "15"
    ),
    new Spell(
        "Danse de la lame révérée",
        "Lance 1d8 de projectiles sur une cible multiple ou unique. Chaque projectile doit être paré ou esquivé par la cible.",
        3,
        "célérité",
        Element.null,
        "10"
    ),
];
