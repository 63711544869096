
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Game} from '@/types/Interfaces';
import {ref, set} from 'firebase/database';
import {db} from '@/firebase';

@Component
export default class KimStatsComponent extends Vue {
    @Prop() private game !: Game;
    @Prop() private id !: number;

    modals: boolean[] = []
    text = '';

    mounted(): void {
        //Initializes the array
        this.game.characters[this.id].stats.forEach(() => {
            this.modals.push(false);
        })
        document.addEventListener('keydown', this.escapeKeyHandler);
    }

    async changeStat(index: number, modifier: string): Promise<void> {
        try {
            const playerStat: { name: string, current: number, max: number } = this.game.characters[this.id].stats[index];
            if (modifier === 'add') {
                if (playerStat.current >= playerStat.max) {
                    playerStat.current = playerStat.max;
                } else playerStat.current++;
            } else playerStat.current--;
            await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`), this.game.characters[this.id]);
        } catch (error) {
            console.log('error : ' + error);
        }
    }

    escapeKeyHandler(e: KeyboardEvent): void {
        if (e.key === 'Escape') {
            this.closeModals();
        }
    }

    openModal(index: number): void {
        this.closeModals();
        this.modals[index] = true;
        this.$nextTick(() => {
            document.getElementById(`modalstat-${index}`)?.focus();
        });
        this.$forceUpdate();
    }

    closeModals(): void {
        this.text = '';
        this.modals.forEach((modal, index) => {
            this.modals[index] = false;
        });
        this.$forceUpdate();
    }

    async handleModal(index: number): Promise<void> {
        const backup = this.game.characters[this.id].stats[index].current;
        try {
            let value = backup;
            if (this.text.charAt(0) === '+') {
                value += parseInt(this.text.substring(1));
            } else if (this.text.charAt(0) === '-') {
                value -= parseInt(this.text.substring(1));
            } else {
                value = parseInt(this.text);
            }
            value = Math.min(this.game.characters[this.id].stats[index].max, value);
            this.game.characters[this.id].stats[index].current = value;
            await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}`),
                this.game.characters[this.id]);
        } catch (error) {
            this.game.characters[this.id].stats[index].current = backup;
            console.log('error on input');
        }
        this.closeModals();
    }
}
