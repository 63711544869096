
import {Component, Prop, Vue} from 'vue-property-decorator';
import {get, ref, set} from 'firebase/database';
import {db} from '@/firebase';
import {IRoll} from '@/types/Roll';
import {Game} from '@/types/Interfaces';
import {PadoRoll} from '@/types/PadoRoll';
import PadoStatsComponent from '@/components/ParceDomine/PadoStatsComponent.vue';
import {NormalRoll} from '@/types/NormalRoll';
import AzrPlayerInventoryComponent from "@/components/Azuria/AzrPlayerInventoryComponent.vue";
import LotdStatsComponent from "@/components/LandOfTheDread/LotdStatsComponent.vue";
import KimSpellsComponent from "@/components/Kimakaan/KimSpellsComponent.vue";
import KimPlayerInventoryComponent from "@/components/Kimakaan/KimPlayerInventoryComponent.vue";
import PadoMapComponent from "@/components/ParceDomine/PadoMapComponent.vue";
import LotdCharSheetComponent from './LotdCharSheetComponent.vue';
import { LotdRoll } from '@/types/LotdRoll';
import LotdSpellsComponent from './LotdSpellsComponent.vue';

@Component({
    components: {
        PadoMapComponent,
        KimPlayerInventoryComponent,
        KimSpellsComponent,
        LotdStatsComponent,
        AzrPlayerInventoryComponent,
        PadoStatsComponent,
        LotdCharSheetComponent,
        LotdSpellsComponent,
    },
})
export default class LotdPlayerComponent extends Vue {
    @Prop() private game !: Game;

    secret = false;
    modalHealth = false;
    modalRi = false;
    modalHealthText = '';
    modalRiText = '';

    normalRollText = '';

    async lycanthropeTransformStart() {
        if (this.game.characters[this.$store.state.user.uid].informations.filter(i => i.name == 'transformation-vigueur')[0].value as number != 0) {
            return;
        }
        const roll = new NormalRoll(false, this.game.type, `Transformation ${this.game.characters[this.$store.state.user.uid].name}`);
        const lvl = this.game.characters[this.$store.state.user.uid].informations.filter(i => i.name == 'niveau')[0].value as number;
        roll.roll(`1d10 + ${lvl}`);
        await this.addRoll(roll);
        await this.addGlobalRoll(roll);
        this.game.characters[this.$store.state.user.uid].informations.filter(i => i.name == 'transformation-modifier')[0].value = roll.rolled;
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}/informations`), this.game.characters[this.$store.state.user.uid].informations);


        const rollVigueur = new NormalRoll(false, this.game.type, `Transformation (vigueur) ${this.game.characters[this.$store.state.user.uid].name}`);
        if (lvl < 4) {
            rollVigueur.roll('1d2')
        } else if (lvl < 7) {
            rollVigueur.roll('1d2 + 1')
        } else {
            rollVigueur.roll('1d3 + 1')
        }
        await this.addRoll(rollVigueur);
        await this.addGlobalRoll(rollVigueur);

        this.game.characters[this.$store.state.user.uid].stats.filter(s => s.name == 'vigueur')[0].max += rollVigueur.rolled
        this.game.characters[this.$store.state.user.uid].informations.filter(i => i.name == 'transformation-vigueur')[0].value = rollVigueur.rolled
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}`), this.game.characters[this.$store.state.user.uid]);
    }

    async lycanthropeTransformEnd() {
        this.game.characters[this.$store.state.user.uid].informations.filter(i => i.name == 'transformation-modifier')[0].value = 0
        this.game.characters[this.$store.state.user.uid].stats.filter(s => s.name == 'vigueur')[0].max -= this.game.characters[this.$store.state.user.uid].informations.filter(i => i.name == 'transformation-vigueur')[0].value as number
        this.game.characters[this.$store.state.user.uid].informations.filter(i => i.name == 'transformation-vigueur')[0].value = 0
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}`), this.game.characters[this.$store.state.user.uid]);
    }

    async addRoll(roll: IRoll): Promise<void> {
        if (!this.game.characters[this.$store.state.user.uid].rolls) {
            this.game.characters[this.$store.state.user.uid].rolls = [];
        }
        this.game.characters[this.$store.state.user.uid].rolls = [roll, ...this.game.characters[this.$store.state.user.uid].rolls];
        if (this.game.characters[this.$store.state.user.uid].rolls.length > 20) this.game.characters[this.$store.state.user.uid].rolls.pop();
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}/rolls`), this.game.characters[this.$store.state.user.uid].rolls);
    }

    async addGlobalRoll(roll: IRoll): Promise<void> {
        let globalRolls: IRoll[] | null = null;
        await get(ref(db, `/jdrs/${this.game.id}/rolls`)).then(snap => {
            globalRolls = snap.val();
        });
        if (globalRolls === null) globalRolls = [];
        globalRolls = [roll, ...globalRolls];
        globalRolls = globalRolls.slice(0, 100);
        await set(ref(db, `/jdrs/${this.game.id}/rolls`), globalRolls);
    }

    async roll(stat: string): Promise<void> {
        if (this.game && this.game.characters[this.$store.state.user.uid]) {
            let fake = 0
            let activated = false;
            await get(ref(db, `/jdrs/${this.game.id}/fake`)).then(snap => {
                const ret = snap.val();
                fake = ret.value;
                activated = ret.activated;
            });

            const roll: LotdRoll = new LotdRoll(stat, this.game.characters[this.$store.state.user.uid].name);
            let modifier = 0;
            const classe = this.game.characters[this.$store.state.user.uid].informations.filter(i => i.name == 'classe')[0].value;
            if (classe == 'vampire') {
                const sang = this.game.characters[this.$store.state.user.uid].stats[2].current;
                const maxSang = this.game.characters[this.$store.state.user.uid].stats[2].max;

                if (sang == 0) modifier = -3;
                else if (sang == 1) modifier = -2;
                else if (sang == 2 || sang == 3) modifier = -1;
                else if (sang == maxSang) modifier = 3;
                else if (sang == maxSang - 1) modifier = 2;
                else if (sang == maxSang - 2 || sang == maxSang -3) modifier = 1;
            } else if (classe == 'lycanthrope') {
                const transformationModifier = this.game.characters[this.$store.state.user.uid].informations.filter(i => i.name == 'transformation-modifier')[0].value as number;
                if (transformationModifier == 2) modifier = -3;
                else if (transformationModifier == 20) modifier = 3;
                else if (transformationModifier == 3 || transformationModifier == 4) modifier = -2
                else if (transformationModifier == 19 || transformationModifier == 18) modifier = 2
                else if (transformationModifier >= 4 && transformationModifier <= 6) modifier = -1
                else if (transformationModifier >= 13 && transformationModifier <= 17) modifier = 1
            }
            await roll.roll(this.game.characters[this.$store.state.user.uid], this.secret, this.game.fake, modifier);
            await this.addRoll(roll);
            await this.addGlobalRoll(roll);
        }
    }

    async rollNormal(): Promise<void> {
        const roll = new NormalRoll(this.secret, this.game.type, this.game.characters[this.$store.state.user.uid].name);
        roll.roll(this.normalRollText);
        await this.addRoll(roll);
        await this.addGlobalRoll(roll);
    }

    async useAbility(name: string): Promise<void> {
        await (set(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}`), this.game.characters[this.$store.state.user.uid]));
        const abilityRoll = new PadoRoll(name, this.game.characters[this.$store.state.user.uid].name);
        abilityRoll.critic = false;
        abilityRoll.passed = true;
        abilityRoll.rolled = 0;
        abilityRoll.text = name;
        abilityRoll.setCSSClass();
        await this.addGlobalRoll(abilityRoll);
        await this.addRoll(abilityRoll);
    }
}
